import PrimeReact from "primereact/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useRef, useContext, useState, Fragment } from "react";
import { Divider } from "primereact/divider";
//import "primereact/resources/themes/arya-blue/theme.css";
//import "primereact/resources/themes/mdc-light-indigo/theme.css";

//import "primereact/resources/primereact.min.css";
//import "primeicons/primeicons.css";
//import "primeflex/primeflex.css";

import "../App.scss";
import "@fortawesome/fontawesome-free/css/all.css";

import LoginForm from "../common/Auth/LoginForm";
import ActivatePage from "../common/Auth/ActivatePage";

import { AppContext } from "../common/AppContext";
import Main from "./main/Main";
import BOMain from "./main/BOMain";
import Menu from "./main/Menu";
import Footer from "./main/Footer";
import { Toast } from "primereact/toast";
import ProfilePage from "../common/Auth/ProfilePage";
import TsPage from "../common/TsPage";
import BusinessCampaigns from "./business/BusinessCampaigns";
import ShowID from "./consumer/ShowID";
import RunCampaign from "./business/RunCampaign";
import PlayCampaign from "./business/PlayCampaign";
import Tools from "../common/Tools";
import ShowPrize from "./business/ShowPrize";
import CPlayCampaign from "./consumer/CPlayCampaign";
import ResultPage from "./common/ResultPage";
import AfterPaymentRedirect from "./common/AfterPaymentRedirect";
import Authentication from "../common/Auth/Authentication";
import PasswordChangeForm from "../common/Auth/PasswordChangeForm";
import POSAdmin from "../components/admin/POSAdmin";
import POSAdminContextProvider from "./admin/POSAdminContextProvider";
import LoadingPage from "../common/Utils/LoadingPage";
import LogoutPage from "../common/Auth/LogoutPage";
import RegisterForm from "../common/Auth/RegisterForm";
import AppTools from "./AppTools";
import AdminRegisterForm from "./business/AdminRegisterForm";
import BusinessPage from "./business/BusinessPage";
import { getEnv } from "../common/Utils/MultiEnv";

function App() {
  PrimeReact.ripple = true;
  const toast = useRef(null);
  const {
    hint,
    error,
    setError,
    setHint,
    setToken,
    setParty,
    setProfile,
    initLookups,
    initConfigs,
  } = useContext(AppContext);

  const [ready, setReady] = useState(false);

  const getLoadTb = () => {
    let r = "";
    if (typeof window.location !== "undefined") {
      if (window.location.search.indexOf("?table=") !== -1) {
        r = window.location.search.substr(7);
      }
    }
    return r;
  };

  const loadTb = getLoadTb();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      //toast.current.clear();
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        sticky: false,
        life: 6000,
      });
      setError(null);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (hint) {
      //toast.current.clear();
      toast.current.show({
        severity: "info",
        summary: "Information",
        detail: hint,
        sticky: false,
        life: 6000,
      });
      setHint(null);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hint]);

  useEffect(() => {
    let zKey = Tools.getCookie("zKey");
    if (zKey) {
      Authentication.autoLogin(
        zKey,
        (message) => {
          setReady(true);
        },
        (tk, pr) => {
          setToken(tk);
          initLookups(tk);
          if (pr.profile.role === AppTools.BUSINESS) {
            initConfigs(tk);
          }
          setProfile({ ...pr.profile });
          delete pr.profile;
          setParty({ ...pr });
          setReady(true);
        }
      );
    } else {
      if (loadTb.length !== 0) {
        Authentication.customerLogin(
          loadTb,
          (message) => {
            setReady(true);
          },
          (tk, pr, bus) => {
            setToken(tk);
            initLookups(tk);
            if (pr.profile.role === AppTools.BUSINESS) {
              initConfigs(tk);
            }
            setProfile({ ...pr.profile, business: bus });
            delete pr.profile;
            setParty({ ...pr });
            setReady(true);
          }
        );
      } else {
        setReady(true);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) return <LoadingPage />;
  return (
    <Fragment>
      <Toast ref={toast} position="bottom-right" />
      <div className="wrapper">
        <BrowserRouter>
          <Menu />
          <Routes>
            <Route exact path="/TS" element={<TsPage />} />
            <Route
              path="/admin/*"
              element={
                <POSAdminContextProvider>
                  <POSAdmin />
                </POSAdminContextProvider>
              }
            />

            <Route path="/form" element={<POSAdmin />} />

            <Route path="/" element={<Main />} />
            <Route exact path="/cvc" element={<BOMain />} />
            <Route path="/activate" element={<ActivatePage />} />
            <Route path="/payment/*" element={<AfterPaymentRedirect />} />
            <Route exact path="/login" element={<LoginForm />} />
            <Route
              exact
              path="/resetpassword"
              element={<PasswordChangeForm />}
            />
            <Route
              exact
              path="/changepassword"
              element={<PasswordChangeForm />}
            />
            <Route exact path="/logout" element={<LogoutPage />} />
            <Route exact path="/register" element={<RegisterForm />} />
            <Route exact path="/profile" element={<ProfilePage />} />
            <Route exact path="/consumer" element={<ProfilePage />} />
            {/* <Route exact path="/business" element={<ProfilePage />} /> */}
            <Route exact path="/myid" element={<ShowID />} />
            <Route exact path="/myentries" element={<ProfilePage />} />
            <Route exact path="/campaigns" element={<BusinessCampaigns />} />
            <Route exact path="/result" element={<ResultPage />} />
            <Route exact path="/business" element={<BusinessPage />} />
            <Route
              exact
              path="/bo_rb"
              element={<AdminRegisterForm organization="CVC" />}
            />
            <Route
              exact
              path="/addCampaign"
              element={<BusinessCampaigns openAdd={true} />}
            />
            <Route exact path="/run" element={<RunCampaign />} />
            <Route exact path="/showprize" element={<ShowPrize />} />
            <Route exact path="/play/*" element={<PlayCampaign />} />
            <Route exact path="/cplay/*" element={<CPlayCampaign />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className="surface-section px-4 md:px-6 lg:px-8">
        <div className="border-top-1 border-300 pt-5 text-center">
          <img
            src={`${getEnv()}logo512.png`}
            alt={process.env.REACT_APP_DEPLOYMENT_NAME}
            height="128"
          />

          <div className="flex align-items-center justify-content-center my-5">
            <a
              className="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5"
              style={{ width: "2.5rem", height: "2.5rem" }}
              href="/"
            >
              <i className="pi pi-twitter"></i>
            </a>
            <a
              className="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5"
              style={{ width: "2.5rem", height: "2.5rem" }}
              href="/"
            >
              <i className="pi pi-facebook"></i>
            </a>
            <a
              className="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center"
              style={{ width: "2.5rem", height: "2.5rem" }}
              href="/"
            >
              <i className="pi pi-github"></i>
            </a>
          </div>
          <div className="text-center">
            <a
              className="mr-5 text-sm text-600 cursor-pointer"
              target="_blank"
              href="privacy.html"
            >
              Privacy Policy
            </a>
            <a
              className="text-sm text-600 cursor-pointer"
              rel="noreferrer"
              target="_blank"
              href="https://app.websitepolicies.com/policies/view/3a5s32cr"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </div>
      <Divider />
      {/* <Footer className="footer" /> */}
    </Fragment>
  );
}

export default App;
