import epsonUtil from "../../common/Prn/epsonUtil";
import Tools from "../../common/Tools";
import POSAdminPrc from "./POSAdminPrc";

const POSPrintPrc = {
  cashOut: (configs, uid, token, onError, onSuccess) => {
    POSAdminPrc.loadUserOrders(
      uid,
      token,
      (msg) => onError(msg),
      (orders, user, timein, logs) => {
        let all = orders.map((o) => {
          return { ...o, json: JSON.parse(o.json) };
        });
        let data = {
          totalOrders: 0,
          delivery: 0,
          walkin: 0,
          pickup: 0,
          dinein: 0,
          canceled: 0,
          finances: new Map(),
          items: new Map(),
          citems: new Map(),
        };
        all.forEach((o) => {
          data.totalOrders++;
          switch (o.order_type) {
            case POSAdminPrc.DELIVER:
              data.delivery++;
              break;
            case POSAdminPrc.DINE_IN:
              data.dinein++;
              break;
            case POSAdminPrc.PICK_UP:
              data.pickup++;
              break;
            case POSAdminPrc.WALK_IN:
              data.walkin++;
              break;
            default:
          }
          if (o.canceled) data.canceled++;

          o.json.finances
            .filter((f) => f.show)
            .forEach((f) => {
              if (data.finances.has(f.key)) {
                let a = data.finances.get(f.key);
                a.amount += f.amount;
              } else {
                data.finances.set(f.key, f);
              }
            });

          if (o.canceled) {
            o.json.orderItems.forEach((oi) => {
              let n = oi.menu == null ? oi.deal.name : oi.menu.name;
              if (data.citems.has(n)) {
                let a = data.citems.get(n);
                a.count += oi.count;
              } else {
                data.citems.set(n, { name: n, count: oi.count });
              }
            });
          } else {
            o.json.orderItems.forEach((oi) => {
              let n = oi.menu == null ? oi.deal.name : oi.menu.name;
              if (data.items.has(n)) {
                let a = data.items.get(n);
                a.count += oi.count;
              } else {
                data.items.set(n, { name: n, count: oi.count });
              }
            });
          }
        });

        let prn = configs.find((c) => c.name === "printer_local").value;

        epsonUtil.print(
          prn,
          (p) => {
            p.addTextAlign(p.ALIGN_CENTER);
            p.addTextSmooth(true);
            p.addTextDouble(false, true);
            p.addTextStyle(false, false, true, p.COLOR_1);
            p.addText("Report:" + user.name + "\n");
            let dt = new Date();
            p.addText(
              dt.toDateString() +
                " / " +
                dt.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }) +
                "\n"
            );
            p.addTextAlign(p.ALIGN_LEFT);
            p.addTextDouble(false, false);
            p.addTextStyle(false, false, false, p.COLOR_1);
            dt = new Date(Date.parse(timein));
            p.addText(
              "\n\n Start: " +
                dt.toDateString() +
                " / " +
                dt.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }) +
                "\n"
            );
            let secs = (new Date().getTime() - dt.getTime()) / 1000;

            dt = new Date();
            p.addText(
              "   End: " +
                dt.toDateString() +
                " / " +
                dt.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }) +
                "\n"
            );

            p.addText("Worked:  " + Tools.secondsToDhms(secs) + "\n\n");

            p.addText(
              POSAdminPrc.strLeftRight("Total Orders:", data.totalOrders) + "\n"
            );
            p.addText(
              POSAdminPrc.strLeftRight("  # Delivery:", data.delivery) + "\n"
            );
            p.addText(
              POSAdminPrc.strLeftRight("   # Pick up:", data.pickup) + "\n"
            );
            p.addText(
              POSAdminPrc.strLeftRight("    # Walkin:", data.walkin) + "\n"
            );
            p.addText(
              POSAdminPrc.strLeftRight("   # Dine In:", data.dinein) + "\n"
            );
            p.addText(
              POSAdminPrc.strLeftRight("  # Canceled:", data.canceled) + "\n"
            );

            p.addText("\n");

            let fins = Array.from(data.finances.values()).sort(
              (a, b) => a.row - b.row
            );
            fins.forEach((f) => {
              p.addText(
                POSAdminPrc.strLeftRight(
                  f.key,
                  Tools.formatCurrency(Math.abs(f.amount))
                ) + "\n"
              );
            });

            p.addText("\n\n");

            if (data.finances.has("Cash")) {
              let cash = Math.abs(data.finances.get("Cash").amount);
              let tip = Math.abs(data.finances.get("Tip").amount);

              p.addText(
                POSAdminPrc.strLeftRight(
                  "Cash:",
                  Tools.formatCurrency(Math.abs(cash))
                ) + "\n"
              );
              p.addText(
                POSAdminPrc.strLeftRight(
                  "Tip:",
                  Tools.formatCurrency(Math.abs(tip))
                ) + "\n"
              );
              if (cash > tip) {
                p.addText(
                  POSAdminPrc.strLeftRight(
                    "Cash Due (pay):",
                    Tools.formatCurrency(Math.abs(cash - tip))
                  ) + "\n"
                );
              } else if (cash < tip) {
                p.addText(
                  POSAdminPrc.strLeftRight(
                    "Cash Due (get):",
                    Tools.formatCurrency(Math.abs(tip - cash))
                  ) + "\n"
                );
              } else {
                p.addText(" *** No Cash to pay or get ***\n");
              }
            } else if (data.finances.has("Tip")) {
              let tip = Math.abs(data.finances.get("Tip").amount);
              p.addText(
                POSAdminPrc.strLeftRight(
                  "Cash Due (get):",
                  Tools.formatCurrency(Math.abs(tip))
                ) + "\n"
              );
            } else {
              p.addText(" *** No Cash to pay or get ***\n");
            }

            if (data.items.size !== 0) {
              p.addText("\n\n *** Itemized Report ***\n");

              let ois = Array.from(data.items.values()).sort(
                (a, b) => a.name - b.name
              );
              ois.forEach((f) => {
                p.addText(POSAdminPrc.strLeftRight(f.name, f.count) + "\n");
              });
            }

            if (data.citems.size !== 0) {
              p.addText("\n\n *** Itemized Canceled Report ***\n");

              let ois = Array.from(data.citems.values()).sort(
                (a, b) => a.name - b.name
              );
              ois.forEach((f) => {
                p.addText(POSAdminPrc.strLeftRight(f.name, f.count) + "\n");
              });
            }
            p.addText("\n\n");

            p.addTextAlign(p.ALIGN_CENTER);
            p.addTextSmooth(true);
            p.addTextDouble(true, true);
            p.addTextStyle(false, false, true, p.COLOR_1);
            p.addText("\n\n *** END ***\n");
            onSuccess();
          },
          (code) => {
            onError(code);
          }
        );
      }
    );
  },

  dayEndPrn: (configs, orders, onError, onSuccess) => {
    let all = orders.map((o) => {
      return { ...o, json: JSON.parse(o.json) };
    });
    let data = {
      totalOrders: 0,
      delivery: 0,
      walkin: 0,
      pickup: 0,
      dinein: 0,
      canceled: 0,
      finances: new Map(),
      items: new Map(),
      citems: new Map(),
    };
    all.forEach((o) => {
      data.totalOrders++;
      switch (o.order_type) {
        case POSAdminPrc.DELIVER:
          data.delivery++;
          break;
        case POSAdminPrc.DINE_IN:
          data.dinein++;
          break;
        case POSAdminPrc.PICK_UP:
          data.pickup++;
          break;
        case POSAdminPrc.WALK_IN:
          data.walkin++;
          break;
        default:
      }
      if (o.canceled) data.canceled++;

      o.json.finances
        .filter((f) => f.show)
        .forEach((f) => {
          if (data.finances.has(f.key)) {
            let a = data.finances.get(f.key);
            a.amount += f.amount;
          } else {
            data.finances.set(f.key, f);
          }
        });

      if (o.canceled) {
        o.json.orderItems.forEach((oi) => {
          let n = oi.menu == null ? oi.deal.name : oi.menu.name;
          if (data.citems.has(n)) {
            let a = data.citems.get(n);
            a.count += oi.count;
          } else {
            data.citems.set(n, { name: n, count: oi.count });
          }
        });
      } else {
        o.json.orderItems.forEach((oi) => {
          let n = oi.menu == null ? oi.deal.name : oi.menu.name;
          if (data.items.has(n)) {
            let a = data.items.get(n);
            a.count += oi.count;
          } else {
            data.items.set(n, { name: n, count: oi.count });
          }
        });
      }
    });

    let prn = configs.find((c) => c.name === "printer_local").value;

    epsonUtil.print(
      prn,
      (p) => {
        p.addTextAlign(p.ALIGN_CENTER);
        p.addTextSmooth(true);
        p.addTextDouble(false, true);
        p.addTextStyle(false, false, true, p.COLOR_1);
        p.addText("Day End Report\n");
        let dt = new Date();
        p.addText(
          dt.toDateString() +
            " / " +
            dt.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }) +
            "\n"
        );
        p.addTextAlign(p.ALIGN_LEFT);
        p.addTextDouble(false, false);
        p.addTextStyle(false, false, false, p.COLOR_1);

        p.addText(
          POSAdminPrc.strLeftRight("Total Orders:", data.totalOrders) + "\n"
        );
        p.addText(
          POSAdminPrc.strLeftRight("  # Delivery:", data.delivery) + "\n"
        );
        p.addText(
          POSAdminPrc.strLeftRight("   # Pick up:", data.pickup) + "\n"
        );
        p.addText(
          POSAdminPrc.strLeftRight("    # Walkin:", data.walkin) + "\n"
        );
        p.addText(
          POSAdminPrc.strLeftRight("   # Dine In:", data.dinein) + "\n"
        );
        p.addText(
          POSAdminPrc.strLeftRight("  # Canceled:", data.canceled) + "\n"
        );

        p.addText("\n");

        let fins = Array.from(data.finances.values()).sort(
          (a, b) => a.row - b.row
        );
        fins.forEach((f) => {
          p.addText(
            POSAdminPrc.strLeftRight(
              f.key,
              Tools.formatCurrency(Math.abs(f.amount))
            ) + "\n"
          );
        });

        if (data.items.size !== 0) {
          p.addText("\n\n *** Itemized Report ***\n");

          let ois = Array.from(data.items.values()).sort(
            (a, b) => a.name - b.name
          );
          ois.forEach((f) => {
            p.addText(POSAdminPrc.strLeftRight(f.name, f.count) + "\n");
          });
        }

        if (data.citems.size !== 0) {
          p.addText("\n\n *** Itemized Canceled Report ***\n");

          let ois = Array.from(data.citems.values()).sort(
            (a, b) => a.name - b.name
          );
          ois.forEach((f) => {
            p.addText(POSAdminPrc.strLeftRight(f.name, f.count) + "\n");
          });
        }

        p.addText("\n\n");

        p.addTextAlign(p.ALIGN_CENTER);
        p.addTextSmooth(true);
        p.addTextDouble(true, true);
        p.addTextStyle(false, false, true, p.COLOR_1);
        p.addText("\n\n *** END ***\n");
        onSuccess();
      },
      (code) => {
        onError(code);
      }
    );
  },

  dayEndCheckCO: (configs, logs, token, onError, onSuccess) => {
    if (logs.length === 0) {
      onSuccess();
    } else {
      let l = logs[0];
      logs.shift();
      POSPrintPrc.cashOut(configs, l.user_id, token, onError, () =>
        POSPrintPrc.dayEndCheckCO(configs, logs, token, onError, onSuccess)
      );
    }
  },

  dayEnd: (configs, token, onError, onSuccess) => {
    POSAdminPrc.loadUserOrders(
      0,
      token,
      (msg) => onError(msg),
      (orders, user, timein, logs) => {
        if (logs.length === 0) {
          POSPrintPrc.dayEndPrn(configs, orders, onError, onSuccess);
        } else {
          POSPrintPrc.dayEndCheckCO(configs, logs, token, onError, () =>
            POSPrintPrc.dayEndPrn(configs, orders, onError, onSuccess)
          );
        }
      }
    );
  },

  printCustomerReceipt: (configs, order, guest, cb) => {
    let prn = configs.find((c) => c.name === "printer_local").value;
    let topMsg = configs
      .find((c) => c.name === "message_top")
      .value.replaceAll("\\n", "\n");
    let buttomMsg = configs
      .find((c) => c.name === "message_bottom")
      .value.replaceAll("\\n", "\n");
    let address = configs.find((c) => c.name === "store_address").value;
    let phone = configs.find((c) => c.name === "store_phone").value;

    epsonUtil.print(
      prn,
      (printer) => {
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addTextSmooth(true);
        printer.addTextDouble(true, true);
        printer.addTextStyle(false, false, true, printer.COLOR_1);
        if (order.canceled) {
          printer.addText("** ORDER  CANCELED **\n");
        }
        if (typeof order.web_id === "string") {
          printer.addText("** WEB ORDER #" + order.web_id + " **\n");
        }
        if ((order.partner != null && typeof order.partner) === "object") {
          printer.addText("** Partner ORDER **\n" + order.partner.name + "\n");
        }
        printer.addTextDouble(false, true);
        if (!order.canceled) {
          printer.addText(topMsg + "\n");
        }
        printer.addText(address + "\n");
        printer.addText(phone + "\n");

        printer.addText("__________________________________________\n\n");

        printer.addTextDouble(false, false);
        printer.addTextStyle(false, false, false, printer.COLOR_1);

        printer.addText(
          POSAdminPrc.getType(order) + "  #" + order.order_number + "\n"
        );
        printer.addText(order.order_time + "\n\n");

        if (order.type === POSAdminPrc.DINE_IN) {
          printer.addText("Table: " + order.table.name + "\n");
        } else if (order.type === POSAdminPrc.PICK_UP) {
          printer.addText(
            order.customer.name + " " + order.customer.phone + "\n"
          );
        } else if (order.type === POSAdminPrc.DELIVER) {
          printer.addText(
            order.customer.name + " " + order.customer.phone + "\n"
          );
          printer.addText(order.customer.address + "\n");
        }

        printer.addText("__________________________________________\n\n");

        printer.addTextAlign(printer.ALIGN_LEFT);

        if (order.canceled) {
        } else {
          order.orderItems
            .filter((oi) => guest === 0 || oi.guest === guest)
            .forEach((oi, index) => {
              let name = oi.menu == null ? oi.deal.name : oi.menu.name;
              //let price = oi.menu == null ? oi.deal.price : oi.menu.price;
              let price = order.finances.find(
                (f) => f.key === "oi-" + index + "_total"
              ).amount;
              printer.addText(
                POSAdminPrc.strLeftRight(
                  `${oi.count === 1 ? "" : oi.count + " X "} ${name}`,
                  Tools.formatCurrency(price)
                ) + `\n`
              );
              oi.options.forEach((o) => {
                printer.addText(POSAdminPrc.getOptionLabel(o));
                printer.addText(", ");
              });
              printer.addText("\n");
            });

          printer.addText("__________________________________________\n\n");

          printer.addTextAlign(printer.ALIGN_RIGHT);

          order.finances
            .filter((f) => f.show && (guest === 0 || f.guest === guest))
            .sort((f1, f2) => f1.row - f2.row)
            .forEach((f) => {
              printer.addText(
                POSAdminPrc.strLeftRight(
                  f.key,
                  f.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                ) + "\n"
              );
            });
        }

        printer.addText("\n");
        printer.addText("\n");

        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addTextDouble(false, true);

        if (order.note != null && order.note.length !== 0) {
          printer.addText("****** NOTE ******\n");
          printer.addText(order.note + "\n");
          printer.addText("******************\n");
        }

        if (!order.canceled) {
          printer.addText(buttomMsg + "\n");
        }
      },
      (code) => {
        cb(code);
      }
    );
  },

  sendOrderToKitchen: (order, printers, cb, index = 0) => {
    if (index >= printers.length) {
      cb(null);
      return;
    }
    let prn = printers[index];
    let items = order.orderItems.filter(
      (oi) =>
        oi.menu != null &&
        oi.menu.printer === prn.value &&
        oi.printed < oi.count
    );
    if (items.length !== 0)
      epsonUtil.print(
        prn.value,
        (printer) => {
          printer.addTextAlign(printer.ALIGN_CENTER);
          printer.addTextSmooth(true);
          printer.addTextDouble(true, false);
          printer.addTextStyle(false, false, true, printer.COLOR_1);
          printer.addText("** " + prn.name + " **");
          printer.addText("\n");
          printer.addText("\n");
          printer.addText("Order #");
          printer.addText(order.order_number);
          printer.addText("\n");
          printer.addText(POSAdminPrc.getType(order));
          printer.addText("\n");
          if (order.type === POSAdminPrc.DINE_IN) {
            printer.addText("Table: ");
            printer.addText(order.table.name);
            if (order.guest !== 0) {
              printer.addText("\nGuest: ");
              printer.addText(order.guest);
            }
          }
          printer.addText("\n");

          printer.addText("_____________________\n");
          printer.addTextAlign(printer.ALIGN_LEFT);
          printer.addTextSmooth(false);
          printer.addTextDouble(false, false);
          printer.addTextStyle(false, false, false, printer.COLOR_1);
          items.forEach((oi) => {
            if (oi.menu != null && oi.printed < oi.count) {
              printer.addText(
                POSAdminPrc.strLeftRight(
                  `${oi.menu.name}`,
                  `${oi.count - oi.printed}`
                ) + `\n\t`
              );
              if (oi.guest !== 0) {
                printer.addText("(G: #" + oi.guest + ")\t");
              }
              oi.options.forEach((o) => {
                printer.addText(POSAdminPrc.getOptionLabel(o));
                printer.addText(", ");
              });
              printer.addText("\n");
              oi.printed = oi.count;
            }
          });
        },
        (code) => {
          POSPrintPrc.sendOrderToKitchen(order, printers, cb, index + 1);
        }
      );
    else POSPrintPrc.sendOrderToKitchen(order, printers, cb, index + 1);
  },

  sendOrderToKitchenPrx: (order, printers, cb) => {
    let pCnt = printers.length;
    let cnt = 0;
    let err = null;
    if (pCnt === 0) cb(null);
    printers.forEach((prn, index) => {
      let items = order.orderItems.filter(
        (oi) =>
          oi.menu != null &&
          oi.menu.printer === prn.value &&
          oi.printed < oi.count
      );
      if (items.length !== 0) {
      } else {
        cnt++;
        if (cnt === pCnt) {
          cb(err);
        }
      }
    });
  },
};
export default POSPrintPrc;
