import React, { Fragment, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Menu as PMenu } from "primereact/menu";
import { AppContext } from "../../common/AppContext";
import { Menubar } from "primereact/menubar";
import { Avatar } from "primereact/avatar";
import AppTools from "../AppTools";
import Tools from "../../common/Tools";
import { getEnv } from "../../common/Utils/MultiEnv";

const Menu = () => {
  const navigate = useNavigate();
  const { token, profile, hideMenu } = useContext(AppContext);
  const menu = useRef(null);

  const shareItems = [
    {
      role: null,
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: (event) => {
        navigate("/");
      },
    },
  ];
  const unsecureItems = [
    {
      role: null,
      label: "Login",
      icon: "pi pi-fw pi-user",
      command: (event) => {
        navigate("/login");
      },
    },
    {
      role: null,
      label: "Join",
      icon: "pi pi-fw pi-user-plus",
      command: (event) => {
        navigate("/register");
      },
    },
    // {
    //   role: null,
    //   label: "Result",
    //   icon: "pi pi-fw pi-eye",
    //   command: (event) => {
    //     navigate("/result");
    //   },
    // },
  ];

  const authItems1 = [
    {
      role: null,
      label: "Edit",
      icon: "pi pi-fw pi-user-edit",
      command: (event) => {
        navigate("/profile");
      },
    },
    {
      role: null,
      label: "Change Password",
      icon: "pi pi-fw pi-key",
      command: (event) => {
        navigate("/changepassword");
      },
    },
    {
      role: null,
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      command: (event) => {
        navigate("/logout");
      },
    },
  ];

  const authItems2 = [
    {
      role: null,
      label: "Edit",
      icon: "pi pi-fw pi-user-edit",
      command: (event) => {
        navigate("/profile");
      },
    },
    {
      role: null,
      label: "Change Password",
      icon: "pi pi-fw pi-key",
      command: (event) => {
        navigate("/changepassword");
      },
    },
    {
      role: null,
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      command: (event) => {
        navigate("/logout");
      },
    },
  ];

  const secureItems = [
    {
      role: AppTools.CONSUMER,
      label: "Play",
      icon: "pi pi-fw pi-camera",
      command: (event) => {
        navigate("/cplay");
      },
    },
    {
      role: AppTools.CONSUMER,
      label: "My ID",
      icon: "pi pi-fw pi-id-card",
      command: (event) => {
        navigate("/myid");
      },
    },
    {
      role: AppTools.CONSUMER,
      label: "Result",
      icon: "pi pi-fw pi-eye",
      command: (event) => {
        navigate("/result");
      },
    },
    {
      role: AppTools.BUSINESS,
      label: "Result",
      icon: "pi pi-fw pi-eye",
      command: (event) => {
        navigate("/result");
      },
    },
    {
      role: AppTools.BUSINESS,
      label: "Campagins",
      icon: "pi pi-fw pi-tags",
      command: (event) => {
        navigate("/campaigns");
      },
    },
    {
      role: AppTools.BUSINESS,
      label: "Run",
      icon: "pi pi-fw pi-play",
      command: (event) => {
        navigate("/run");
      },
    },
    {
      role: AppTools.BUSINESS,
      label: "POS",
      icon: "fas  fa-cash-register",
      command: (event) => {
        navigate("/admin");
      },
    },
  ];

  const getEnd = () => {
    return (
      <Fragment>
        {/* <Button
          label="FAQ"
          icon="pi pi-question"
          className="p-button-rounded p-button-success"
        />
        <Button
          label="Plans"
          icon="pi pi-bars"
          className="p-button-rounded p-button-info"
          style={{ marginLeft: "10px", marginRight: "40px" }}
        /> */}
        <img
          alt="logo"
          src={`${getEnv()}logo192.png`}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          height="40"
          className="p-mr-2"
        ></img>
      </Fragment>
    );
  };

  const getStart = () => {
    if (token == null || profile == null) return "";
    return (
      <Fragment>
        <PMenu
          model={profile.role === AppTools.CONSUMER ? authItems1 : authItems2}
          ref={menu}
          popup
        />
        <Avatar
          image={Tools.getImagePath(profile.id, "profile")}
          className="p-mr-2"
          onImageError={(e) => {
            e.target.src = Tools.getDefaultImage();
          }}
          size="small"
          shape="circle"
          onClick={(e) => menu.current.toggle(e)}
        />
      </Fragment>
    );
  };
  const items = shareItems.concat(
    token == null || profile == null
      ? unsecureItems
      : secureItems.filter(function (item) {
          return item.role == null || item.role === profile.role;
        })
  );
  if (hideMenu) {
    return (
      <div className="surface-card border-round">
        <img
          src={`${getEnv()}logo192.png`}
          alt={process.env.REACT_APP_DEPLOYMENT_NAME}
          className="mxb-4"
          style={{ width: "64px" }}
        />
      </div>
    );
  } else {
    return <Menubar model={items} start={getStart} end={getEnd} />;
  }
};

export default Menu;
