import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { QRCode } from "react-qrcode-logo";
import { getEnv } from "./MultiEnv";

const MessageBox = ({
  show,
  icon,
  header,
  message,
  qr = null,
  okFunc = null,
  hideFunc,
}) => {
  return (
    <Dialog
      dlgLabel="Display"
      position="top"
      visible={show}
      onHide={() => hideFunc()}
      modal
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      style={{ width: "40vw" }}
      closable={true}
      showHeader={true}
      footer={
        <div className=" border-top-1 surface-border pt-3 flex">
          <Button
            icon="pi pi-times"
            onClick={() => hideFunc()}
            label="Close"
            className="p-button-outlined w-full mr-2"
          />
        </div>
      }
      header={header}
    >
      <div key="DKD" className="flex flex-column align-items-center">
        <span
          className="flex align-items-center justify-content-center text-pink-300 mr-3 border-circle mb-3"
          style={{ width: "64px", height: "64px" }}
        >
          <i className={`${icon} text-5xl`}></i>
        </span>
        {/* <div className="font-medium text-2xl text-900">{header}</div> */}
      </div>
      <span className="line-height-3 p-0 m-0">{message}</span>
      {qr != null && (
        <div className="flex flex-column align-items-center my-4">
          {/* <QRCode value={qr} size={200} /> */}
          <QRCode
            value={qr}
            eyeRadius={2}
            size={300}
            removeQrCodeBehindLogo={true}
            qrStyle="dots"
            logoImage={`/images/${getEnv()}qr-logo.png`}
            ecLevel="H"
          />{" "}
        </div>
      )}
    </Dialog>
  );
};

export default MessageBox;
