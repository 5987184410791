const vars = new Map();
let i = new Map();
i.set("cityvipclub.com", "City VIP Club");
i.set("playreward.ca", "Play Rewards");
i.set("noworkrewards.com", "No Work Rewards");
i.set("localhost", "Local - Dev");
vars.set("REACT_APP_DEPLOYMENT_NAME", i);

function getVar(varName, defValue) {
  var e = window.location.hostname.toLocaleLowerCase();
  console.log(e);
  let m = vars.get(varName);
  if (m) {
    let v = m.get(window.location.hostname.toLocaleLowerCase());
    if (v) return v;
  }
  return defValue;
}

function getEnv() {
  var e = window.location.hostname.toLocaleLowerCase();
  if (e === "cityvipclub.com") return "cvc_";
  if (e === "playreward.ca") return "plr_";
  if (e === "noworkrewards.com") return "nwr_";
  if (e === "localhost") return "lch_";
  return "";
}

export { getVar, getEnv };
