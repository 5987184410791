import React, { useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";
import { QRCode } from "react-qrcode-logo";
import { getEnv } from "../../common/Utils/MultiEnv";

const ShowID = () => {
  const { party } = useContext(AppContext);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex justify-content-center pt-5">
        <div className="grid formgrid p-fluid w-12">
          <div className="field col-12 md:col-6 flex justify-content-center flex-grow-1">
            <span className="text-3xl font-semibold text-yellow-600">
              {party.firstname === "" ? "Guest" : party.firstname}{" "}
              {party.lastname === "" ? "Guest" : party.lastname}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-content-center">
        <div className="grid formgrid p-fluid w-12">
          <div className="field col-12 md:col-6 flex justify-content-center flex-grow-1">
            {/* <QRCode value={party.qr} size={300} title="Amir" level="H" /> */}
            <QRCode
              value={party.qr}
              eyeRadius={2}
              size={300}
              removeQrCodeBehindLogo={true}
              qrStyle="dots"
              logoImage={`/images/${getEnv()}qr-logo.png`}
              ecLevel="H"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowID;
