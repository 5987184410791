import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../common/AppContext";
import BPrc from "../business/BusinessPrc";
import MessageBox from "../../common/Utils/MessageBox";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../common/Utils/LoadingPage";
import WScanner from "../../common/Utils/WScanner";
import StripePaymentPage from "../common/StripePaymentPage";
import Tools from "../../common/Tools";
import ManagedDialog from "../../common/Utils/ManagedDialog";

const CPlayCampaign = () => {
  const { token, setError, party } = useContext(AppContext);
  const [ready, setReady] = useState(false);
  const [wMsg, setWMsg] = useState("Checking your camera ...");

  const scannerRef = useRef(null);

  const [showPayment, setShowPayment] = useState(false);
  const [processPayment, setProcessPayment] = useState(null);
  const getPayment = (c, fn) => {
    if (c.entry_cost !== 0) {
      setProcessPayment(() => (data) => {
        if (data.error == null) {
          fn();
        }
        setShowPayment(false);
      });
      setShowPayment(true);
    } else {
      fn();
    }
  };

  const [confirm, setConfirm] = useState(false);
  const [conf_header, setConf_header] = useState(null);
  const [conf_message, setConf_message] = useState(null);
  const [conf_qr, setConf_qr] = useState(null);
  const [conf_func, setConf_func] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const navigate = useNavigate();

  const Msg = (header, message, qr = null, func = null) => {
    setConf_header(header);
    setConf_message(message);
    setConf_qr(qr);
    setConf_func(func);
    setConfirm(true);
  };

  const drawPrizes = (counter) => {
    let canWin = campaign.items.filter((i) => i.odds <= counter);
    if (canWin.length === 0) {
      return (
        <ul>
          <li>Keep playing!</li>
        </ul>
      );
    } else {
      return (
        <ul>
          You have enough points to redeem for:
          {canWin.map((p) => (
            <li>
              {p.prize} - {p.odds} stamps
            </li>
          ))}
        </ul>
      );
    }
  };
  const showResult = (data) => {
    switch (data.mode) {
      case 0:
        if (data.prize == null) {
          Msg(
            "Thank you for playing",
            `Dear ${
              data.customer.firstname.length <= 1
                ? "player"
                : data.customer.firstname + " " + data.customer.lastname
            }, you didn't win any prize, good luck next time.`
          );
        } else {
          Msg(
            "Congradulations",
            `Dear ${
              data.customer.firstname.length <= 1
                ? "player"
                : data.customer.firstname + " " + data.customer.lastname
            }, you just won! To redeam your ${
              data.prize.prize
            } please take a photo of QR below and present it to us. If you are registered or provided your email address a confirmation is also sent to your email.`,
            data.qr,
            () => {}
          );
        }
        break;
      case 1:
        Msg("Thank you for playing", [
          `Dear ${
            data.customer.firstname.length <= 1
              ? "player"
              : data.customer.firstname + " " + data.customer.lastname
          }, you have `,
          <strong>{data.entry.counter}</strong>,
          " stamps from this campaign!",
          <ul> {drawPrizes(data.entry.counter)}</ul>,
        ]);
        break;
      case 2:
        Msg(
          "Thank you for playing",
          `Dear ${
            data.customer.firstname.length <= 1
              ? "player"
              : data.customer.firstname + " " + data.customer.lastname
          }, good luck! Your name is added to the draw list.`,
          null
        );
        break;
      case 3:
        Msg(
          "Congradulations",
          [
            `Dear ${
              data.customer.firstname.length <= 1
                ? "player"
                : data.customer.firstname + " " + data.customer.lastname
            }, you just earned `,
            <strong> {data.prize.odds} </strong>,
            `% discount on your purchase. please take a photo of QR below and present it to us. If you are registered or provided your email address a confirmation is also sent to your email.`,
          ],
          data.qr,
          () => {}
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (ready) {
      scannerRef.current.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  if (party == null) {
    navigate("/");
    return "";
  }

  return (
    <div className="flex justify-content-center">
      {!ready && <LoadingPage message={wMsg} />}
      <ManagedDialog
        closeFn={setShowPayment}
        visible={showPayment}
        maximized={false}
        header="Payment Processing"
        style={{ width: "500px" }}
      >
        <StripePaymentPage
          amount={campaign == null ? 0 : campaign.entry_cost * 100}
          campaign={campaign == null ? null : campaign.qr}
          processFn={processPayment}
        />
      </ManagedDialog>
      <WScanner
        ref={scannerRef}
        header="Campaign QR Code"
        readyFn={() => {
          setReady(true);
        }}
        abortFn={() => {
          navigate("/");
        }}
        errorFn={() => {
          navigate("/");
        }}
        scanFn={(data) => {
          scannerRef.current.stop();
          let qr = data;
          let pos = qr.indexOf("qr=");
          if (pos !== -1) {
            qr = qr.substring(pos + 3);
          }
          setWMsg("Entering the campaign ...");
          //setReady(false);
          BPrc.getCampaign(
            qr,
            (msg) => setError(msg),
            (data) => {
              setCampaign(data.campaign);
              getPayment(data.campaign, () =>
                BPrc.runCampaignForClient(
                  false,
                  party.qr,
                  qr,
                  0,
                  token,
                  (message) => {
                    setError(message);
                    navigate("/");
                  },
                  (data) => {
                    Tools.processEmail();
                    setReady(true);
                    showResult(data);
                  }
                )
              );
            }
          );
        }}
      />

      <MessageBox
        show={confirm}
        icon="fas fa-trophy"
        header={conf_header}
        message={conf_message}
        qr={conf_qr}
        okFunc={conf_func}
        hideFunc={() => {
          setConfirm(false);
          navigate("/");
        }}
      />
    </div>
  );
};

export default CPlayCampaign;
