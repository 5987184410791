import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../common/AppContext";

import { AdminContext } from "./POSAdminContextProvider";
import OrderTypeSelector from "./OrderTypeSelector";
import OrderItemMenuSelector from "./OrderItemMenuSelector";
import OrderSourceSelector from "./OrderSourceSelector";
import OrderScreen from "./OrderScreen";
import OrderItemMenuOptionSelector from "./OrderItemMenuOptionSelector";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import OrderItemMemo from "./OrderItemMemo";
import OrderItemDealSelector from "./OrderItemDealSelector";
import Layout from "./Layout";
import GetPhone from "../common/GetPhone";
import CustomerPage from "./CustomerPage";
import ItemSelector from "./ItemSelector";
import SelectGuest from "./SelectGuest";
import SelectBillOption from "./SelectBillOption";
import PaymentPage from "./PaymentPage";
import Tools from "../../common/Tools";
import OrderCancelationDlg from "./OrderCancelationDlg";
import LoadingPage from "../../common/Utils/LoadingPage";
import POSAdminPrc from "./POSAdminPrc";
import ManagedDialog from "../../common/Utils/ManagedDialog";
import SelectEntity from "../../common/CRUD/SelectEntity";
import POSPrintPrc from "./POSPrintPrc";

const OrderProcess = ({ closeFn = null, orderID = 0 }) => {
  const { token, setError, setHint, configs } = useContext(AppContext);
  const { menus, deals, menuModifiers, setMenuModifiers, user, findMenu } =
    useContext(AdminContext);

  const [guest, setGuest] = useState(0);
  const [printing, setPrinting] = useState(false);
  const [runProcess, setRunProcess] = useState(null);

  const makeNewOrder = () => {
    return {
      state: POSAdminPrc.START,
      type: "",
      customer: null,
      orderItems: [],
      finances: [],
      server: user,
      table: null,
      billOption: "Single",
      guests: 1,
      partner: null,
      changed: false,
      station: Tools.getCookie("STATION"),
      id: 0,
    };
  };

  const [order, setOrder] = useState(makeNewOrder());
  const [itemIndex, setItemIndex] = useState(-1);
  const [redoFin, setRedoFin] = useState(false);

  const [showCancelReason, setShowCancelReason] = useState(false);
  const processCancelOrder = (reason) => {
    setShowCancelReason(false);
    POSAdminPrc.saveOrder(
      {
        ...order,
        canceled: true,
        note: "Order canceled by " + user.name + ", reason given is " + reason,
      },
      token,
      (message) => setError(message),
      (o) => {
        POSPrintPrc.printCustomerReceipt(
          configs,
          {
            ...order,
            canceled: true,
            note:
              "Order canceled by " + user.name + ", reason given is " + reason,
          },
          0,
          () => {}
        );
        processOrderScreen(POSAdminPrc.DISCAD_ORDER_CMD);
      }
    );
  };

  const [showOrderType, setShowOrderType] = useState(false);
  const processOrderType = (t) => {
    setShowOrderType(false);
    if (t === POSAdminPrc.DINE_IN) {
      // Get the table
      setShowTableSelector(true);
    } else if (t === POSAdminPrc.WALK_IN) {
      setOrder({ ...order, state: POSAdminPrc.ADD_ORDER_ITEM_CMD, type: t });
      setShowOrderSource(true);
    } else {
      // Get the customer
      setOrder({ ...order, type: t });
      setShowGetCustomer(true);
    }
    setRedoFin(true);
  };

  const [showBillOptions, setShowBillOptions] = useState(false);
  const processBillOptions = (b) => {
    setOrder({ ...order, billOption: b });
    setShowBillOptions(false);
  };

  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const processPayment = (paid) => {
    let newFins = order.finances.concat(
      paid.map((p, index) => ({
        key: p.method,
        amount: -1 * p.amount,
        show: true,
        guest,
        type: "P",
        row: p.method === "Tip" ? 11 : p.method === "Card Fee" ? 8 : 20 + index,
      }))
    );

    setOrder({ ...order, finances: newFins });
    setShowPaymentScreen(false);
    setRunProcess(POSAdminPrc.SAVE_ORDER_CMD);
    //    setRedoFin(true);
  };

  const [orderList, setOrderList] = useState([]);
  const [showLoadOrder, setShowLoadOrder] = useState(false);
  const processLoadOrder = (o) => {
    if (o.id === 0) {
      o.paid = false;
      o.made = false;
      o.order_time = null;
      o.delivered = false;
      o.closed = false;
      o.canceled = false;
      o.note = null;
      o.partner = null;
      o.partner_id = null;
      o.json.partner = null;
      o.json.server = user;
      o.json.finances = [];
      o.json.billOption = "Single";
      o.json.station = Tools.getCookie("STATION");
      o.json.type = order.type;

      o.json.orderItems.forEach((oi) => {
        oi.printed = 0;
        oi.served = 0;
      });
    }
    let newO = {
      ...o.json,
      changed: o.changed,
      order_number: o.order_number,
      order_time: o.order_time,
      id: o.id,
      paid: o.paid,
      made: o.made,
      delivered: o.delivered,
      closed: o.closed,
      canceled: o.canceled,
      note: o.note,
      station: o.station,
      partner_id: o.partner_id,
    };

    newO.orderItems.forEach((oi) => {
      oi.menu = findMenu(oi.menu.id, menus);
      if (oi.menu === null) {
        setError(
          "There are items in this menu that are no longer offered. This menu can not be loaded."
        );
        return;
      }
    });

    setOrder(newO);
    setShowLoadOrder(false);
    //setRedoFin(true);
  };

  const [showGetCustomer, setShowGetCustomer] = useState(false);
  const processGetCustomer = (customerPhone) => {
    POSAdminPrc.findCustomer(
      customerPhone,
      token,
      (message) => setError(message),
      (aCustomer) => {
        if (aCustomer == null) {
          confirmDialog({
            message: `Customer wasn't found, do you want to add a new customer?`,
            className: "text-3xl",
            icon: "pi pi-info-circle text-3xl",
            acceptClassName: "text-2xl p-button-danger",
            rejectClassName: "text-2xl p-button-info",
            accept: () => {
              POSAdminPrc.addCustomer(
                customerPhone,
                token,
                (message) => {
                  setError(message);
                },
                (aCustomer) => {
                  setShowGetCustomer(false);
                  setOrder({ ...order, customer: aCustomer });
                  setShowCustomerPage(true);
                  setRedoFin(true);
                }
              );
            },
            reject: () => {},
          });
        } else {
          setShowGetCustomer(false);
          setOrder({ ...order, customer: aCustomer });
          setShowCustomerPage(true);
          setRedoFin(true);
        }
      }
    );
  };

  const [showCustomerPage, setShowCustomerPage] = useState(false);
  const processCustomerPage = () => {
    setShowCustomerPage(false);
    if (order.state === POSAdminPrc.START) {
      setOrder({ ...order, state: POSAdminPrc.ADD_ORDER_ITEM_CMD });
      setShowOrderSource(true);
    }
    setRedoFin(true);
  };

  const [showTableSelector, setShowTableSelector] = useState(false);
  const processTableSelector = (table) => {
    if (table.order_id != null && table.order_id !== 0) {
      // Load order if server is the same
      if (table.user.id === user.id) {
        POSAdminPrc.loadOrder(
          table.order_id,
          token,
          (msg) => setError(msg),
          (o) => {
            processLoadOrder({ ...o, json: JSON.parse(o.json), change: false });
            return;
          }
        );
      } else {
        setError("This table is not serverd by you.");
        return;
      }
    }
    if (order.table != null) {
      // change table!
      if (table.user !== null) {
        setError(
          "This table is not empty, you can only change table to an empty table."
        );
        return;
      }
      POSAdminPrc.changeTableServer(
        order.table.uuid,
        table.uuid,
        user.id,
        order.id,
        token,
        (message) => setError(message),
        () => {
          if (order.id !== 0) {
            POSAdminPrc.saveOrder(
              { ...order, table, changed: false },
              token,
              (msg) => setError(msg),
              (o) => {
                setHint("Table is changed sucessfully.");
              }
            );
          }
          setOrder({ ...order, table });
        }
      );
    } else if (table.user == null) {
      // Assign the table to this user;
      table.user = user;
      POSAdminPrc.setTableServer(
        table.uuid,
        user.id,
        token,
        (message) => setError(message),
        () => {
          setOrder({
            ...order,
            table,
            state: POSAdminPrc.ADD_ORDER_ITEM_CMD,
            type: POSAdminPrc.DINE_IN,
          });
          setShowOrderSource(true);
        }
      );
    } else if (table.user.id !== user.id) {
      setError("This table is served by someone else and you can't take it.");
      return;
    } else {
      setOrder({
        ...order,
        table,
        state: POSAdminPrc.ADD_ORDER_ITEM_CMD,
        type: POSAdminPrc.DINE_IN,
      });
      setShowOrderSource(true);
    }
    setShowTableSelector(false);
    setRedoFin(true);
  };

  const [showOrderItemMemo, setShowOrderItemMemo] = useState(false);
  const processOrderItemMemo = (note, cost) => {
    let row = order.orderItems[itemIndex];
    row.note = note;
    row.extra_cost = cost;
    let newItems = [...order.orderItems];
    newItems.splice(itemIndex, 1, row);
    setOrder({ ...order, orderItems: newItems });
    setShowOrderItemMemo(false);
    setRedoFin(true);
  };

  const [showOrderSource, setShowOrderSource] = useState(false);
  const processOrderSource = (t) => {
    setShowOrderSource(false);
    setOrder({ ...order, state: t });
    if (t === POSAdminPrc.SELECT_FROM_MENU_ITEMS) {
      setShowOrderFromMenu(true);
    } else if (t === POSAdminPrc.SELECT_FROM_DEAL_ITEMS) {
      setShowOrderFromDeal(true);
    } else processOrderScreen(t);
  };

  const [showOrderFromDeal, setShowOrderFromDeal] = useState(false);
  const processOrderFromDeal = (deal) => {
    let beforeCnt = order.orderItems.length;
    setShowOrderFromDeal(false);
    let id = order.orderItems.length + 1;

    makeNewOrderItem(id, null, null, deal, true, -1, 0, true);

    addItemFromDeal(id, 0, deal);
    setItemIndex(beforeCnt + 1);
    setRedoFin(true);
  };

  const makeNewOrderItem = (
    id,
    menu,
    dm,
    deal,
    master,
    seq,
    parent,
    processed = false
  ) => {
    order.orderItems.push({
      id,
      menu,
      dm,
      deal,
      options: [],
      count: 1,
      printed: 0,
      served: 0,
      guest: guest,
      note: "",
      extra_cost: 0,
      master,
      seq,
      parent,
      processed,
      timestamp: new Date(),
    });
  };

  const addItemFromDeal = (id, index, deal) => {
    if (index === deal.deal_menus.length) {
      setShowOrderItemOption(true);
    } else {
      let dm = deal.deal_menus[index];
      let menu = findMenu(dm.menu_id, menus);
      let group_items = deal.deal_menus.filter(
        (i) => i.group_id === dm.group_id && dm.group_id !== 0
      );
      if (group_items.length > 1) {
        setDealMenuGroup({
          dealMenuIndex: index,
          dealMenuGroupSet: group_items,
          dealMenuId: id,
          deal,
        });
        return;
      }

      if (dm.price === 0) {
        makeNewOrderItem(
          order.orderItems.length + 1,
          menu,
          dm,
          deal,
          false,
          index,
          id,
          false
        );
        addItemFromDeal(id, index + 1, deal);
      } else {
        if (dm.upgrade === "") {
          confirmDialog({
            message: `Do you want to add ${menu.name} for $${dm.price} to this deal?`,
            className: "text-3xl",
            icon: "pi pi-info-circle text-3xl",
            acceptClassName: "text-2xl p-button-danger",
            rejectClassName: "text-2xl p-button-info",
            accept: () => {
              makeNewOrderItem(
                order.orderItems.length + 1,
                menu,
                dm,
                deal,
                false,
                index,
                id,
                false
              );
              addItemFromDeal(id, index + 1, deal);
            },
            reject: () => {
              addItemFromDeal(id, index + 1, deal);
            },
          });
        } else {
          let menu2 = findMenu(dm.upgrade, menus);
          confirmDialog({
            message: `Do you want to upgrade ${menu.name} to ${menu2.name} for $${dm.price} on this deal?`,
            className: "text-3xl",
            icon: "pi pi-info-circle text-3xl",
            acceptClassName: "text-2xl p-button-danger",
            rejectClassName: "text-2xl p-button-info",
            accept: () => {
              makeNewOrderItem(
                order.orderItems.length + 1,
                menu2,
                dm,
                deal,
                false,
                index,
                id,
                false
              );
              addItemFromDeal(id, index + 1, deal);
            },
            reject: () => {
              makeNewOrderItem(
                order.orderItems.length + 1,
                menu,
                dm,
                deal,
                false,
                index,
                id,
                false
              );
              addItemFromDeal(id, index + 1, deal);
            },
          });
        }
      }
    }
  };

  const [complexTitle, setComplexTitle] = useState({});
  const [complexChild, setComplexChild] = useState({});
  const [complexIndex, setComplexIndex] = useState(-1);
  const [dealMenuGroup, setDealMenuGroup] = useState({
    dealMenuGroupSet: [],
    dealMenuIndex: -1,
    deal: undefined,
  });
  const [showSelectComplexChild, setShowSelectComplexChild] = useState(false);
  const [showSelectDealmenuOfGroup, setShowSelectDealmenuOfGroup] =
    useState(false);

  const processSelectDealmenuGroupItem = (dealmenu) => {
    //let deal = deals.find((d) => d.id === dealmenu.obj.deal_id);
    let menu = findMenu(dealmenu.obj.menu_id, menus);
    setDealMenuGroup({
      ...dealMenuGroup,
      dealMenuGroupSet: [],
      indexInc: dealMenuGroup.dealMenuGroupSet.length,
    });
    makeNewOrderItem(
      order.orderItems.length + 1,
      menu,
      dealmenu.obj,
      dealMenuGroup.deal,
      false,
      dealMenuGroup.dealMenuIndex,
      dealMenuGroup.dealMenuId,
      false
    );
  };

  const processSelectComplexChild = (menu) => {
    let mom = order.orderItems[order.orderItems.length - complexIndex - 1];
    makeNewOrderItem(
      order.orderItems.length + 1,
      menu,
      null,
      null,
      false,
      complexIndex,
      mom.id,
      false
    );
    setShowSelectComplexChild(false);
    setTimeout(() => {
      processComplexMenu(mom.menu, complexIndex + 1);
    }, 400);
  };

  const processComplexMenu = (menu, index) => {
    if (menu.children.length === index) {
      setItemIndex(order.orderItems.length - menu.children.length);
      setOrder({ ...order, state: POSAdminPrc.UPDATE_ORDER_ITEM_OPTIONS });
      setShowOrderItemOption(true);
      setRedoFin(true);
      return;
    } else {
      setComplexIndex(index);
      setComplexChild(
        menu.children.sort((a, b) => b.sequence - a.sequence)[index]
      );
      setComplexTitle("Select " + menu.children[index].name);
      setShowSelectComplexChild(true);
    }
  };

  const [showOrderFromMenu, setShowOrderFromMenu] = useState(false);
  const processOrderFromMenu = (menu) => {
    setShowOrderFromMenu(false);
    if (typeof menu.children !== "undefined") {
      // Complex menu
      let id = order.orderItems.length + 1;
      makeNewOrderItem(id, menu, null, null, true, -1, -1, true);
      processComplexMenu(menu, 0);
      return;
    } else {
      if (menu.deal_count <= 1) {
        makeNewOrderItem(
          order.orderItems.length + 1,
          menu,
          null,
          null,
          true,
          0,
          0,
          false
        );
        setItemIndex(order.orderItems.length - 1);
      } else {
        let id = order.orderItems.length + 1;
        makeNewOrderItem(id, menu, null, null, true, -1, -1, true);
        for (let i = 0; i !== menu.deal_count; i++) {
          makeNewOrderItem(
            order.orderItems.length + 1,
            menu,
            null,
            null,
            false,
            i,
            id,
            false
          );
        }
        setItemIndex(order.orderItems.length - menu.deal_count);
      }
    }

    setOrder({ ...order, state: POSAdminPrc.UPDATE_ORDER_ITEM_OPTIONS });
    setShowOrderItemOption(true);
    setRedoFin(true);
  };

  const getThisPayment = () => {
    if (order.amount === 0) return 0;
    if (order.billOption === "Single") return order.amount;
    if (order.billOption === "Guests") return order.amount;
    if (order.billOption === "Two") return order.amount / 2;
    if (order.billOption === "Three") return order.amount / 3;
    if (order.billOption === "Four") return order.amount / 4;
    if (order.billOption === "Five") return order.amount / 5;
    if (order.billOption === "Six") return order.amount / 6;
  };
  const setShowOrderItemOptionPrc = (v) => {
    setShowOrderItemOption(false);
    let row = order.orderItems[itemIndex];
    row.processed = true;
    let newItems = [...order.orderItems];
    newItems.splice(itemIndex, 1, row);
    setOrder({ ...order, orderItems: newItems });
    setRunProcess(POSAdminPrc.EDIT_ORDER_ITEM_CMD);
    setRedoFin(true);
  };

  const [selectGuest, setSelectGuest] = useState(false);
  const processSelectGuest = (g) => {
    setSelectGuest(false);
    let row = order.orderItems[itemIndex];
    row.guest = g;
    let newItems = [...order.orderItems];
    newItems.splice(itemIndex, 1, row);
    setOrder({ ...order, orderItems: newItems });
    setRedoFin(true);
  };

  const [showOrderItemOption, setShowOrderItemOption] = useState(false);
  const processOrderItemOption = (options) => {
    let newItem = { ...order.orderItems[itemIndex], options };
    let newOrderItems = [...order.orderItems];
    newOrderItems[itemIndex] = newItem;
    setOrder({ ...order, orderItems: newOrderItems });
    setRedoFin(true);
  };

  const printOrderInLoop = (newO) => {
    setPrinting(true);
    POSPrintPrc.printCustomerReceipt(configs, newO, guest, (code) => {
      setPrinting(false);
      confirmDialog({
        message: "Do you want to print again?",
        className: "text-3xl",
        icon: "pi pi-info-circle text-3xl",
        acceptClassName: "text-2xl p-button-danger",
        rejectClassName: "text-2xl p-button-info",
        accept: () => {
          printOrderInLoop(newO);
        },
        reject: () => {
          //          setPrinting(false);
        },
      });
    });
  };

  const processOrderScreen = (cmd, ...args) => {
    if (cmd === POSAdminPrc.GET_ORDER_TYPE) {
      setShowOrderType(true);
    } else if (cmd === POSAdminPrc.DISCAD_ORDER_CHANGES_CMD) {
      order.orderItems = [];
      setItemIndex(-1);
      setGuest(0);
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.DISCAD_ORDER_CMD) {
      if (orderID !== 0) closeFn();
      setShowOrderType(false);
      if (order.table != null && order.id === 0) {
        POSAdminPrc.setTableServer(
          order.table.uuid,
          "NULL",
          token,
          (message) => setError(message),
          () => {}
        );
      }
      setItemIndex(-1);
      setOrder(makeNewOrder());
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.LOAD_ORDER_CMD) {
      POSAdminPrc.loadOrders(
        0,
        token,
        (message) => setError(message),
        (orders) => {
          //let os = orders.map((o) => o.json.replace("\\", ""));
          setOrderList(
            orders.map((o) => {
              return { ...o, json: JSON.parse(o.json), change: false };
            })
          );
          setShowLoadOrder(true);
        }
      );
    } else if (cmd === POSAdminPrc.SAVE_ORDER_CMD) {
      let pOrder = JSON.parse(JSON.stringify(order));
      order.orderItems.forEach((oi) => {
        oi.printed = oi.count;
      });

      POSAdminPrc.saveOrder(
        order,
        token,
        (msg) => setError(msg),
        (o) => {
          setHint("Your order was saved successfully.");
          setOrder({
            ...order,
            change: false,
            order_number: o.order_number,
            order_time: o.order_time,
            id: o.id,
            paid: o.paid,
            made: o.made,
            delivered: o.delivered,
            closed: o.closed,
            canceled: o.canceled,
            note: o.note,
            station: o.station,
            partner_id: o.partner_id,
          });
          POSPrintPrc.sendOrderToKitchen(
            {
              ...pOrder,
              order_number: o.order_number,
              order_time: o.order_time,
              id: o.id,
            },
            configs.filter((c) => c.name.indexOf("printer_") !== -1),
            (code) => {}
          );
          if (POSAdminPrc.isPaid(order, 0)) {
            setRunProcess(POSAdminPrc.PRINT_CMD);
            //            processOrderScreen(POSAdminPrc.PRINT_CMD);
          } else {
            setRunProcess(POSAdminPrc.DISCAD_ORDER_CMD);
            // processOrderScreen(POSAdminPrc.DISCARD_ORDER_CHANGES_CMD);
          }
        }
      );
    } else if (cmd === POSAdminPrc.CHANGE_TABLE_CMD) {
      setShowTableSelector(true);
    } else if (cmd === POSAdminPrc.ADD_REMOVE_GUEST_CMD) {
      let guest = args[0];
      if (guest === 0) {
        setOrder({ ...order, guests: order.guests + 1 });
      } else {
        let goi = order.orderItems.find((oi) => oi.guest >= guest);
        if (typeof goi === "undefined") {
          setOrder({ ...order, guests: guest <= 2 ? 1 : guest - 1 });
        } else {
          setError(
            "You can only remove a guest who has no order items assigned to them or guests after them."
          );
        }
      }
    } else if (cmd === POSAdminPrc.CHANGE_GUEST_CMD) {
      if (order.guests === 1) {
        setError("This table has only one guest!");
        return;
      }
      setItemIndex(args[0]);
      setSelectGuest(true);
    } else if (cmd === POSAdminPrc.SELECT_PARTNER_CMD) {
      if (args[0] == null || args[0].id === 0) {
        setOrder({ ...order, partner: null, partner_id: null });
      } else {
        setOrder({ ...order, partner: args[0], partner_id: args[0].id });
      }
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.SHOW_CUSTOMER_CMD) {
      setShowCustomerPage(true);
    } else if (cmd === POSAdminPrc.CANCEL_ORDER_CMD) {
      setShowCancelReason(true);
    } else if (cmd === POSAdminPrc.BILL_OPTIONS_CMD) {
      setShowBillOptions(true);
    } else if (cmd === POSAdminPrc.SET_GUEST_CMD) {
      setGuest(args[0]);
    } else if (cmd === POSAdminPrc.PRINT_CMD) {
      if (order.id === 0) {
        POSAdminPrc.saveOrder(
          order,
          token,
          (msg) => setError(msg),
          (o) => {
            let newO = {
              ...order,
              changed: false,
              order_number: o.order_number,
              order_time: o.order_time,
              id: o.id,
              paid: o.paid,
              made: o.made,
              delivered: o.delivered,
              closed: o.closed,
              canceled: o.canceled,
              note: o.note,
              station: o.station,
              partner_id: o.partner_id,
            };
            printOrderInLoop(newO);
            if (POSAdminPrc.isPaid(order, 0)) {
              setRunProcess(POSAdminPrc.DISCAD_ORDER_CMD);
              //              processOrderScreen(POSAdminPrc.DISCARD_ORDER_CHANGES_CMD);
            } else {
              setOrder(newO);
            }
          }
        );
      } else {
        printOrderInLoop(order);
        if (POSAdminPrc.isPaid(order, 0)) {
          setRunProcess(POSAdminPrc.DISCAD_ORDER_CMD);
          //processOrderScreen(POSAdminPrc.DISCARD_ORDER_CHANGES_CMD);
        }
      }
    } else if (cmd === POSAdminPrc.PAY_CMD) {
      setGuest(args[0]);
      if (args[0] === 0 && order.billOption === "Guests") {
        setError(
          "Select a guest to go to their payment or change the order's billing option to Single Bill."
        );
        return;
      }

      if (POSAdminPrc.isPaid(order, args[0])) {
        setError(
          "You can't change payments on a paid order, please consider cancelling the order and opening a new one."
        );
        return;
      }

      setShowPaymentScreen(true);
    } else if (cmd === POSAdminPrc.LOAD_LAST_ORDER_CMD) {
      POSAdminPrc.loadOrder(
        order.customer.last_order_id,
        token,
        (message) => setError(message),
        (o) =>
          processLoadOrder({
            ...o,
            json: JSON.parse(o.json),
            id: 0,
            change: true,
          })
      );
    } else if (cmd === POSAdminPrc.LOAD_ORDER_HIS_CMD) {
      POSAdminPrc.loadOrders(
        order.customer.id,
        token,
        (message) => setError(message),
        (orders) => {
          setOrderList(
            orders.map((o) => {
              return { ...o, json: JSON.parse(o.json), changed: false };
            })
          );
          setShowLoadOrder(true);
        }
      );
    } else if (cmd === POSAdminPrc.ADD_ORDER_ITEM_CMD) {
      setOrder({ ...order, state: cmd });
      setShowOrderSource(true);
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.MEMO_ORDER_ITEM_CMD) {
      setShowOrderItemMemo(true);
    } else if (cmd === POSAdminPrc.INC_ORDER_ITEM_CMD) {
      let row = order.orderItems[args[0]];
      if (!row.master) {
        setError("You can't increase number of this item directly.");
        return;
      }
      if (row.served === row.count) {
        setError(
          "This item is already made, you can't modify this item anymore. Instead you can use Clone function to add a new line as an exact copy of this item."
        );
        return;
      }
      row.count++;
      let newItems = [...order.orderItems];
      newItems.splice(args[0], 1, row);
      setOrder({ ...order, orderItems: newItems });
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.DEC_ORDER_ITEM_CMD) {
      let row = order.orderItems[args[0]];
      if (!row.master) {
        setError("You can't decrease number of this item directly.");
        return;
      }
      if (row.served === row.count) {
        setError(
          "This item is already made, you can't modify this item anymore."
        );
        return;
      }
      if (row.count === 1) {
        processOrderScreen(POSAdminPrc.DEL_ORDER_ITEM_CMD, args[0]);
      } else {
        row.count--;
        let newItems = [...order.orderItems];
        newItems.splice(args[0], 1, row);
        setOrder({ ...order, orderItems: newItems });
      }
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.DEL_ORDER_ITEM_CMD) {
      let row = order.orderItems[args[0]];
      if (!row.master) {
        setError("You can't remove this item directly.");
        return;
      }
      if (row.served === row.count) {
        setError(
          "This item is already made, you can't modify this item anymore."
        );
        return;
      }
      confirmDialog({
        message: "Are you sure you want to delete this item?",
        className: "text-3xl",
        icon: "pi pi-info-circle text-3xl",
        acceptClassName: "text-2xl p-button-danger",
        rejectClassName: "text-2xl p-button-info",
        accept: () => {
          let newItems = [...order.orderItems];
          if (row.menu.count > 1) newItems.splice(args[0], 1 + row.menu.count);
          else if (typeof row.menu.children === "undefined")
            newItems.splice(args[0], 1);
          else newItems.splice(args[0], 1 + row.menu.children.length);
          setItemIndex(-1);
          setOrder({ ...order, orderItems: newItems });
          setRedoFin(true);
        },
        reject: () => {},
      });
    } else if (cmd === POSAdminPrc.EDIT_ORDER_ITEM_CMD) {
      let row = order.orderItems[args[0]];
      if (typeof row === "undefined") {
        row = order.orderItems[itemIndex];
      }
      if (row.master && row.seq === -1) {
        setError(
          "You can't modify this item, instead please modify the sub-items."
        );
        return;
      }
      if (row.served === row.count) {
        setError(
          "This item is already made, you can't modify this item anymore."
        );
        return;
      }
      setItemIndex(args[0]);
      setOrder({ ...order, state: POSAdminPrc.UPDATE_ORDER_ITEM_OPTIONS });
      setShowOrderItemOption(true);
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.CLONE_ORDER_ITEM_CMD) {
      let row = order.orderItems[args[0]];
      if (row.master && row.seq === -1) {
        setError("You can't clone this item.");
        return;
      }
      if (row.parent !== 0) {
        setError("You can't clone this item.");
        return;
      }

      makeNewOrderItem(
        order.orderItems.length + 1,
        row.menu,
        null,
        null,
        row.master,
        row.seq,
        0,
        true
      );
      let newRow = order.orderItems[order.orderItems.length - 1];
      newRow.options = [...row.options];
      setItemIndex(order.orderItems.length - 1);
      setRedoFin(true);
    } else if (cmd === POSAdminPrc.UPDATE_ORDER_ITEM_OPTIONS) {
      let opt = args[0];
      let newItems = [...order.orderItems];
      let mine = newItems.find((oi) => oi.id === opt.oiId);
      let newOptions = mine.options.filter((o) => o.id !== opt.id);
      if (typeof opt.delete !== "boolean") {
        newOptions.push(opt);
      }
      mine.options = newOptions;
      setOrder({ ...order, orderItems: newItems });
      setRedoFin(true);
    } else {
      console.log("Unknows command:" + cmd);
    }
  };

  useEffect(() => {
    POSAdminPrc.getMenuModifiers(
      token,
      (message) => setError(message),
      (data) => {
        setMenuModifiers(
          data.sort(
            (a, b) => (a, b) =>
              a.data.name > b.data.name ? 1 : b.data.name > a.data.name ? -1 : 0
          )
        );
        if (orderID !== 0) {
          POSAdminPrc.loadOrder(
            orderID,
            token,
            (message) => setError(message),
            (o) =>
              processLoadOrder({
                ...o,
                json: JSON.parse(o.json),
                changed: false,
              })
          );
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redoFinances = () => {
    let newO = { ...order };
    let data = POSAdminPrc.buildFinancials(newO, guest, configs);
    setOrder({
      ...order,
      changed: true,
      finances: newO.finances,
      total: data.total,
      amount: data.grandTotal,
    });
  };

  useEffect(() => {
    if (redoFin) {
      setRedoFin(false);
      redoFinances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redoFin]);

  useEffect(() => {
    if (dealMenuGroup.deal === undefined) return;
    if (dealMenuGroup.dealMenuGroupSet.length === 0) {
      setShowSelectDealmenuOfGroup(false);
      addItemFromDeal(
        dealMenuGroup.dealMenuId,
        dealMenuGroup.dealMenuIndex + dealMenuGroup.indexInc,
        dealMenuGroup.deal
      );
    } else {
      setShowSelectDealmenuOfGroup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealMenuGroup]);

  useEffect(() => {
    if (runProcess != null) {
      if (runProcess === POSAdminPrc.EDIT_ORDER_ITEM_CMD) {
        for (let i = itemIndex + 1; i < order.orderItems.length; i++) {
          if (order.orderItems[i].processed === false) {
            let mm = menuModifiers.find(
              (a) => a.id === order.orderItems[i].menu.id
            );
            if (mm.menu_modifiers.length === 0) continue;

            setItemIndex(i);
            setTimeout(() => {
              setShowOrderItemOption(true);
            }, 400);
            break;
          }
        }
        setRunProcess(null);
        return;
      }

      setRunProcess(null);
      processOrderScreen(runProcess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runProcess]);

  const getMenuItemName = () => {
    if (itemIndex === -1) return "Shouldn't happen! index is -1";
    let item = order.orderItems[itemIndex];
    if (item === undefined) return "Shouldn't happen! item is undefined";
    if (item.parent !== 0) {
      if (item.deal == null) {
        if (order.orderItems[item.parent - 1].count > 1)
          return POSAdminPrc.seqNames[item.seq];
        else return item.menu.name;
      }
      if (item.menu == null) return item.deal.name;
      return item.menu.name;
    } else {
      if (item.menu == null) return item.deal.name;
      return item.menu.name;
    }
  };

  const getMenuItemID = () => {
    if (itemIndex === -1) return 0;
    let item = order.orderItems[itemIndex];
    if (item === undefined) return 0;
    return item.id;
  };
  const getMenuItemOptions = () => {
    if (itemIndex === -1 || order.orderItems.length <= itemIndex) return [];
    let item = order.orderItems[itemIndex];
    let mm = menuModifiers.find((a) => a.id === item.menu.id);
    return mm.menu_modifiers;
  };

  const getMenuItemSelectedOptions = () => {
    if (itemIndex === -1 || order.orderItems.length <= itemIndex) return [];
    let item = order.orderItems[itemIndex];
    return item.options;
  };

  if (printing)
    return <LoadingPage message="Receipt is being sent to the printer ...." />;
  return (
    <div className="grid formgrid p-fluid " style={{}}>
      {dealMenuGroup.dealMenuGroupSet.length !== 0 && (
        <ManagedDialog
          closeFn={null}
          visible={showSelectDealmenuOfGroup}
          maximized={false}
          header="Select one of the options for your deal"
        >
          <SelectEntity
            objectSelection={dealMenuGroup.dealMenuGroupSet.map((x, index) => {
              let menu = findMenu(x.menu_id, menus);
              return { name: menu.name, index, obj: x };
            })}
            processFn={processSelectDealmenuGroupItem}
            additionalInfo={dealMenuGroup.dealMenuGroupSet}
          />
        </ManagedDialog>
      )}
      <ManagedDialog
        closeFn={null}
        visible={showSelectComplexChild}
        maximized={false}
        header={complexTitle}
      >
        <SelectEntity
          objectSelection={complexChild.children}
          processFn={processSelectComplexChild}
          additionalInfo={complexChild}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowLoadOrder}
        visible={showLoadOrder}
        maximized={true}
        header="Select an order from the list"
      >
        <ItemSelector all={orderList} processFn={processLoadOrder} />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowCancelReason}
        visible={showCancelReason}
        maximized={false}
        header="Order Cancellation"
      >
        <OrderCancelationDlg processFn={processCancelOrder} />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowPaymentScreen}
        visible={showPaymentScreen}
        maximized={false}
        header="Payment Screen"
      >
        <PaymentPage
          customer={order.customer}
          rate={configs.find((c) => c.name === "dollar_point")}
          processFn={processPayment}
          total={getThisPayment()}
          charges={configs.filter((c) => c.name.indexOf("_charge") !== -1)}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setSelectGuest}
        visible={selectGuest}
        maximized={false}
        header="Change ordered by"
      >
        <SelectGuest
          processFn={processSelectGuest}
          guests={order.guests}
          oi={order.orderItems[itemIndex]}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowBillOptions}
        visible={showBillOptions}
        maximized={false}
        header="How to produce the bill for this order?"
      >
        <SelectBillOption
          processFn={processBillOptions}
          perGuest={order.guests > 1}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowGetCustomer}
        visible={showGetCustomer}
        maximized={false}
        header="Enter Customer's phone number to start"
      >
        <GetPhone processFn={processGetCustomer} />
      </ManagedDialog>

      <ManagedDialog
        closeFn={null}
        visible={showCustomerPage && order.customer !== null}
        maximized={true}
        header="Review customer's profile ..."
      >
        <CustomerPage
          needAddress={order.type === POSAdminPrc.DELIVER}
          customer={order.customer}
          processFn={processCustomerPage}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowOrderType}
        visible={showOrderType}
        maximized={false}
        style={{ width: "90vw" }}
        header="Order Type"
      >
        <OrderTypeSelector processFuc={processOrderType} />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowTableSelector}
        visible={showTableSelector}
        maximized={true}
        style={{ width: "90vw" }}
        header="Select the table"
      >
        <Layout editing={false} processFn={processTableSelector} />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowOrderSource}
        visible={showOrderSource}
        maximized={false}
        style={{ width: "90vw" }}
        header="Order Source"
      >
        <OrderSourceSelector
          processFuc={processOrderSource}
          showHistory={
            order.orderItems.length === 0 &&
            order.customer !== null &&
            order.customer.last_order_id != null &&
            (order.type === POSAdminPrc.DELIVER ||
              order.type === POSAdminPrc.PICK_UP)
          }
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowOrderFromMenu}
        visible={showOrderFromMenu}
        maximized={true}
        style={{ width: "90vw" }}
        header="Select an item from the Menu"
      >
        <OrderItemMenuSelector
          type={order.type}
          head={menus}
          processFuc={processOrderFromMenu}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowOrderFromDeal}
        visible={showOrderFromDeal}
        maximized={true}
        style={{ width: "90vw" }}
        header="Select an item from Deals"
      >
        <OrderItemDealSelector
          deals={deals}
          type={order.type}
          processFuc={processOrderFromDeal}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowOrderItemOptionPrc}
        visible={showOrderItemOption}
        maximized={true}
        style={{ width: "90vw" }}
        header={`Select options for ${getMenuItemName()}`}
      >
        <OrderItemMenuOptionSelector
          oiId={getMenuItemID()}
          allOptions={getMenuItemOptions()}
          myOptions={getMenuItemSelectedOptions()}
          processFuc={processOrderItemOption}
        />
      </ManagedDialog>

      <ManagedDialog
        closeFn={setShowOrderItemMemo}
        visible={showOrderItemMemo}
        style={{ width: "90vw" }}
        header={`Memo for ${getMenuItemName()}`}
      >
        <OrderItemMemo
          oi={order.orderItems[itemIndex]}
          processFuc={processOrderItemMemo}
        />
      </ManagedDialog>

      <OrderScreen
        order={order}
        processFn={processOrderScreen}
        closeFn={closeFn}
        customerUI={orderID !== 0}
      />
    </div>
  );
};

export default OrderProcess;
