let available = true;

const epsonUtil = {
  print: (server, printFn, processFn) => {
    let printer = null;
    let ePosDev = new window.epson.ePOSDevice();
    if (!available) processFn("NA");
    ePosDev.connect(server, 8008, cbConnect);

    function cbConnect(data) {
      if (data === "OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: true, buffer: false },
          cbCreateDevice_printer
        );
      } else {
        console.log("Print error 1 ->", data);
        available = false;
        processFn(data);
      }
    }

    function cbCreateDevice_printer(devobj, retcode) {
      if (retcode === "OK") {
        printer = devobj;
        executeAddedCode();
      } else {
        console.log("Print error 2 ->", retcode);
        processFn(retcode);
      }
    }

    function executeAddedCode() {
      printFn(printer);
      printer.addCut(printer.CUT_FEED);
      printer.send();
      processFn(null);
    }
  },
};

export default epsonUtil;
