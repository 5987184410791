import { Button } from "primereact/button";
import React, { useContext, Fragment, useEffect, useState } from "react";
import { AppContext } from "../../common/AppContext";
import AppTools from "../AppTools";
import BOMain from "./BOMain";
import { useNavigate, useSearchParams } from "react-router-dom";
import Authentication from "../../common/Auth/Authentication";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import BPrc from "../business/BusinessPrc";
import ReactPlayer from "react-player";
import Tools from "../../common/Tools";
import { InputText } from "primereact/inputtext";

const Main = (props) => {
  const {
    profile,
    setToken,
    initLookups,
    initConfigs,
    setProfile,
    setParty,
    setCoordinate,
    setHint,
    setError,
    setNearBusinesses,
    nearBusinesses,
  } = useContext(AppContext);
  let role = profile == null ? null : profile.role;
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (
      (role !== null && role !== AppTools.CONSUMER) ||
      nearBusinesses !== null
    )
      return;

    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinate({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        BPrc.businessesAroundMe(
          {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          (msg) => setError(msg),
          (list) => {
            setNearBusinesses(list);
          }
        );
      },
      (err) => console.log(err),
      options
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const bookDemo = () => {
    if (!Tools.validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    BPrc.bookDemo(
      email,
      (msg) => setError(msg),
      () => {
        setHint("Please check your email");
        setEmail("");
      }
    );
  };

  useEffect(() => {
    let t = searchParams.get("token");
    if (t) {
      Authentication.autoLogin(
        t,
        (message) => {},
        (tk, pr) => {
          setToken(tk);
          initLookups(tk);
          if (pr.profile.role === AppTools.BUSINESS) {
            initConfigs(tk);
          }
          setProfile({ ...pr.profile });
          delete pr.profile;
          setParty({ ...pr });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCampaignDescription = (b) => {
    if (b.campaigns.length === 1) {
      return (
        <Fragment>
          <span className="text-600 font-medium mb-2">
            {b.campaigns[0].name}
          </span>
          <div>
            <span className="inline-block text-lg text-pink-500 mr-1">
              {b.campaigns[0].mode === 0
                ? "Spin & Win Prizes"
                : b.campaigns[0].mode === 1
                ? "Enter & Win Prizes"
                : b.campaigns[0].mode === 2
                ? "Collect Points"
                : b.campaigns[0].items[0].odds + "% Discount"}
            </span>
          </div>
          <span
            className="text-sm text-900"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              inlineSize: "100%",
            }}
          >
            {b.address}
          </span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <span className="text-600 font-medium mb-2">
            {`${b.campaigns.length} Campaigns`}{" "}
          </span>
          <div>
            <span className="inline-block text-lg text-pink-500 mr-1">
              Multiple chances to win
            </span>
          </div>
          <span
            className="text-sm text-900"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              inlineSize: "100%",
            }}
          >
            {b.address}
          </span>
        </Fragment>
      );
    }
  };

  if (role === AppTools.ADMIN) return <BOMain />;
  return (
    <div className="p-1 md:p-3 lg:p-4">
      <div
        className="p-6 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between mb-3"
        style={{
          borderRadius: "1rem",
          background:
            "linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)",
        }}
      >
        <div className="pr-6">
          <div className="text-blue-100 font-medium md:text-xl mb-3">
            Campaigns Made Easy
          </div>
          <div className="text-white font-medium text-xl md:text-5xl">
            Your gateway to daily prizes and savings
          </div>
        </div>
        <div className="mt-4 mr-auto md:mt-0 md:mr-0">
          <Button
            label="Check Results"
            className="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap"
            onClick={(e) => navigate("/result")}
          />
        </div>
      </div>
      <div
        className="border-2 border-dashed surface-border border-round surface-card mt-3"
        style={{ minHeight: "20rem" }}
      >
        <div className="grid grid-nogutter surface-section text-800">
          <div className="col-12 md:col-6 md:p-6 text-center md:text-left flex align-items-center ">
            <section>
              <span className="block text-2xl md:text-6xl font-bold mb-1">
                {process.env.REACT_APP_DEPLOYMENT_NAME}
              </span>
              {(role === null || role === AppTools.BUSINESS) && (
                <Fragment>
                  <div className="md:text-6xl text-3xl text-primary font-bold mb-3">
                    Smart Campaign Management
                  </div>
                  <p className="mt-0 mb-4 text-700 line-height-3">
                    Reward your loyal customers with simple marketing campaigns.
                    Sign up for free and start building a brand, if you like
                    what you see upgrade to paid plans and get more from your
                    campaigns.
                  </p>
                </Fragment>
              )}
              {/* Jump start your campaign by reading our{" "}
              <a href="/faq/index.html">Quick Start Guide</a>. */}
              {role === AppTools.CONSUMER && (
                <Fragment>
                  <div className="text-6xl text-primary font-bold mb-3">
                    Play and Earn
                  </div>
                  <p className="mt-0 mb-4 text-700 line-height-3">
                    Enjoy participating in campaigns and earn free gifts and
                    prizes. Simply scan a campaign QR code to start and try out
                    your chance of winning countless prizes!
                  </p>
                </Fragment>
              )}
              {role === null && (
                <Fragment>
                  <Button
                    label="Add Your Free Campaign"
                    icon="pi pi-star-o"
                    type="button"
                    className="mr-3 p-button-outlined p-button-success"
                    onClick={(e) => {
                      navigate("/addCampaign");
                    }}
                  ></Button>
                  <Button
                    label="Sign Up"
                    icon="pi pi-user-plus"
                    type="button"
                    className="p-button-outlined p-button-success"
                    onClick={(e) => navigate("/register")}
                  ></Button>
                </Fragment>
              )}
            </section>
          </div>
          <div className="col-12 md:col-6 overflow-hidden">
            <img
              src="images/img001.png"
              alt="No Work Rewards"
              className="md:ml-auto block md:h-full"
              style={{
                clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
              }}
            />
          </div>
        </div>
      </div>
      {nearBusinesses == null && (role == null || role === Tools.CONSUMER) && (
        <div className="surface-card p-4 shadow-2 border-round mt-3">
          Finding businesses around you ....
        </div>
      )}
      {nearBusinesses != null && nearBusinesses.length !== 0 && (
        <div className="surface-card p-4 shadow-2 border-round my-3">
          <div className="flex flex-column md:flex-row md:align-items-center md:justify-content-between">
            <span className="text-xl font-medium text-900">
              Business Directory
            </span>
            <div className="flex align-items-center justify-content-between mt-3 md:mt-0">
              <div className="mr-3 flex align-items-center">
                <AvatarGroup className="mr-1">
                  {nearBusinesses.slice(0, 10).map((b, index) => (
                    <Avatar
                      key={index}
                      image={Tools.getImagePath(b.profile_id, "profile")}
                      className="mr-2"
                      onImageError={(e) => {
                        e.target.src = Tools.getNoImage();
                      }}
                      shape="circle"
                    />
                  ))}
                </AvatarGroup>
                {nearBusinesses.length > 10 && (
                  <span className="text-600">
                    +{nearBusinesses.length - 10}
                  </span>
                )}
              </div>
              {nearBusinesses.length > 10 && (
                <Button
                  label="View All"
                  icon="pi pi-search"
                  className="p-button-outlined"
                />
              )}
            </div>
          </div>
          <div className="grid grid-nogutter">
            {nearBusinesses.map((b, index) => (
              <div key={index} className="col-12 md:col-6 xl:col-3 p-3">
                <div className="surface-card shadow-2 border-rounded p-3">
                  <div className="flex flex-column align-items-center border-bottom-1 surface-border pb-3">
                    <img
                      src={Tools.getImagePath(b.profile_id, "profile")}
                      style={{ width: "70px", height: "70px" }}
                      className="mb-3"
                      alt={b.firstname}
                      onError={(e) => (e.target.src = Tools.getNoImage())}
                    />
                    <span className="text-lg text-900 font-medium mb-2">
                      {b.firstname +
                        (b.category == null ? "" : " - " + b.category)}
                    </span>
                    {getCampaignDescription(b)}
                  </div>
                  <div className="flex pt-3">
                    <div className="w-6 pr-2"></div>
                    <div className="w-6 pl-2">
                      <Button
                        icon="pi pi-play"
                        label="Detail"
                        className="p-button-outlined p-button-primary w-full"
                        onClick={(e) =>
                          navigate("/business", { state: { business: b } })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div
        className="md:px-6 lg:px-8 shadow-2 my-3 pt-3"
        style={{
          background:
            "radial-gradient(69.84% 69.84% at 50% 100%, rgba(21, 101, 192, 0.15) 0%, rgba(255, 255, 255, 100%)",
        }}
      >
        <div className="text-900 text-4xl font-bold">
          What is {process.env.REACT_APP_DEPLOYMENT_NAME}?
        </div>
        <div className="text-700 text-lg">
          {process.env.REACT_APP_DEPLOYMENT_NAME} is an exclusive member only
          marketing platform that provides outstanding deals and discounts to
          its members.
        </div>
        <div className="grid mt-7">
          <div className="col-12 md:col-4">
            <div className="shadow-1 p-5 surface-card text-center">
              <div className="border-circle bg-blue-50 p-4 text-blue-600 inline-flex justify-content-center align-items-center mb-4">
                <i className="pi pi-bookmark text-5xl "></i>
              </div>
              <div className="text-900 font-medium text-xl mb-4">
                Free to join
              </div>
              <div className="text-700 text-sm mb-4 line-height-3">
                Becoming a member is free and easy! We don't sell your
                information and never use it to contact you. Your information
                only is used to build the best possible match for you and
                connect you to the businesses that offer them.
              </div>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="shadow-1 p-5 surface-card text-center">
              <div className="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                <i className="pi pi-shopping-cart text-5xl"></i>
              </div>
              <div className="text-900 font-medium text-xl mb-4">
                Earn Rewards for everyday shopping
              </div>
              <div className="text-700 text-sm mb-4 line-height-3">
                Earn points when you use your membership at participating
                businesses Simply show your membership card, receive an agreed
                upon discount and earn point you can redeem later for winning
                valuable prizes and giveaways.
              </div>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="shadow-1 p-5 surface-card text-center">
              <div className="border-circle bg-orange-50 p-4 text-orange-600 inline-flex justify-content-center align-items-center mb-4">
                <i className="pi pi-tags text-5xl"></i>
              </div>
              <div className="text-900 font-medium text-xl mb-4">
                Weekly Prizes
              </div>
              <div className="text-700 text-sm mb-4 line-height-3">
                In addition to all the benefits we have weekly draw to win
                valuable prizes. Simply be a member and use your membership to
                enter the pool. As you use your membership more and more you
                increase your chance of wining!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="surface-section px-4 py-3 md:px-6 lg:px-8 shadow-2 my-3">
        <div className="flex flex-column lg:flex-row align-items-center justify-content-between">
          <div className="text-center lg:text-left lg:pr-8">
            <div className="text-blue-600 font-medium mb-1 text-xl">
              TAKE THE NEXT STEP
            </div>
            <div className="text-900 font-bold text-4xl mb-5">
              Download our mobile application
            </div>
          </div>
          <div className="mt-5 lg:mt-0 flex flex-wrap lg:flex-nowrap">
            <Button
              label="Android"
              icon="pi pi-android text-3xl"
              className="px-5 py-3 mr-0 lg:mr-3 p-button-rounded font-bold mb-3 lg:mb-0 w-full lg:w-auto white-space-nowrap p-button-warning"
              onClick={(e) =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.cityvipclub"
                )
              }
            />
            <Button
              label="iOS"
              icon="pi pi-apple text-3xl"
              className="px-5 py-3 mr-0 lg:mr-3 p-button-rounded font-bold mb-3 lg:mb-0 w-full lg:w-auto white-space-nowrap p-button-warning"
              onClick={(e) =>
                window.open(
                  "https://apps.apple.com/us/app/city-vip-club/id1607680068"
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="flex flex-wrap">
          <div className="w-full xl:w-6 p-3 pr-5">
            <span className="text-indigo-600 font-bold mb-4 block">
              SUPERCHARGE YOUR BUSINESS
            </span>
            <div className="font-bold text-900 text-6xl mb-4">
              Take Control of Your Marketing Strategy
            </div>
            <p className="mt-0 mb-5 text-700 line-height-3 text-xl">
              Let us show you how Play Reward platform can help you boost your
              business and attract more clients without breaking the bank. Watch
              your introductory info-commercial videos and then let us get in
              touch and work with you to find new opportunities.
            </p>
            <div className="relative w-full xl:w-30rem">
              <InputText
                placeholder="Enter your email"
                className="py-3 pl-3 w-full"
                style={{ borderRadius: "30px", paddingRight: "8rem" }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                keyfilter="email"
              />
              <button
                className="p-3 absolute appearance-none p-component border-none bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 text-white outline-none h-full cursor-pointer"
                style={{ borderRadius: "30px", top: "0", right: "0" }}
                onClick={(e) => bookDemo()}
              >
                Book a Demo
              </button>
            </div>
          </div>
          <div
            className="w-full xl:w-6 p-3 flex align-items-center justify-content-center bg-indigo-50 p-4 lg:p-8 mt-5 xl:mt-0"
            style={{ borderRadius: "30px" }}
          >
            <div
              className="relative w-full"
              style={{ paddingBottom: "56.25%", height: "0" }}
            >
              <ReactPlayer
                className="absolute top-0 left-0 w-full h-full"
                url="https://youtube.com/playlist?list=PLB-ppbMMyT7vp0sk9KMcUTbu7gWg62mu8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
