import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AppContext } from "../../common/AppContext";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ScrollPanel } from "primereact/scrollpanel";
import { AdminContext } from "./POSAdminContextProvider";
import { OverlayPanel } from "primereact/overlaypanel";
import OrderItemMenuOptionAttr from "./OrderItemMenuOptionAttr";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import SelectEntity from "../../common/CRUD/SelectEntity";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Tools from "../../common/Tools";
import CustomerMemo from "./CustomerMemo";
import POSAdminPrc from "./POSAdminPrc";
import ManagedDialog from "../../common/Utils/ManagedDialog";
import AdminPrc from "../../common/AdminPrc";

const OrderScreen = ({
  order,
  processFn,
  closeFn = null,
  customerUI = false,
}) => {
  const { token, setError, configs } = useContext(AppContext);
  const { user } = useContext(AdminContext);
  const [socketUrl, setSocketUrl] = useState(null);
  const { sendJsonMessage, readyState } = useWebSocket(socketUrl, {
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  const [oIMenu, setOIMenu] = useState([]);
  const [showOIAction, setShowOIAction] = useState(false);
  const processOIAction = (act) => {
    setShowOIAction(false);
    act.command();
  };

  const [helpMenu] = useState([
    {
      name: "Bill",
      icon: "pi pi-dollar text-5xl",
    },
    {
      name: "Water",
      icon: "fas fa-glass-whiskey text-5xl",
    },
    {
      name: "Spoon",
      icon: "fas fa-utensil-spoon text-5xl",
    },
    {
      name: "Utensils",
      icon: "fas fa-utensils text-5xl",
    },
    {
      name: "Message",
      icon: "pi pi-envelope text-5xl",
    },
    {
      name: "Call",
      icon: "pi pi-bell text-5xl",
    },
  ]);
  const [showHelpAction, setShowHelpAction] = useState(false);
  const processHelpAction = (act) => {
    setShowHelpAction(false);
    processGuestCommandes(act.name.substring(0, 1));
  };

  const [hRatio, setHRatio] = useState(1);
  const [guest, setGuest] = useState(0);
  //const [ setOiIndex] = useState(-1);
  const viewHeight = window.innerHeight - 115;
  const opDetailRef = useRef(null);
  const [optDtl, setOptDtl] = useState({
    modifier: {},
    menuMod: {},
    free: false,
    easy: false,
    hlf: false,
    dbl: false,
    trp: false,
    left: false,
    right: false,
    etc_item: null,
    note: "",
  });

  const [partners, setPartners] = useState([]);

  const processGuestCommandes = (cmd) => {
    if (cmd === "W") {
      sendJsonMessage({
        table_id: order.table.uuid,
        table: order.table.name,
        server: order.server.name,
        time: new Date(),
        msg: "Please bring water",
        from: Tools.getCookie("STATION"),
      });
    } else if (cmd === "S") {
      sendJsonMessage({
        table_id: order.table.uuid,
        table: order.table.name,
        server: order.server.name,
        time: new Date(),
        msg: "Please bring spoon",
        from: Tools.getCookie("STATION"),
      });
    } else if (cmd === "U") {
      sendJsonMessage({
        table_id: order.table.uuid,
        table: order.table.name,
        server: order.server.name,
        time: new Date(),
        msg: "Please bring utensils",
        from: Tools.getCookie("STATION"),
      });
    } else if (cmd === "M") {
      confirmDialog({
        message: (
          <CustomerMemo
            processFn={(msg) => {
              sendJsonMessage({
                table_id: order.table.uuid,
                table: order.table.name,
                server: order.server.name,
                time: new Date(),
                msg: msg,
                from: Tools.getCookie("STATION"),
              });
            }}
          />
        ),
        header: "Message to your server",
        rejectLabel: "Close",
        acceptLabel: "Send",
        //icon: "pi pi-info-circle text-3xl",
        acceptClassName: "hidden",
        rejectClassName: "hidden",
        key: "d005",
        accept: () => {},
        reject: () => {},
      });
    } else if (cmd === "C") {
      sendJsonMessage({
        table_id: order.table.uuid,
        table: order.table.name,
        server: order.server.name,
        time: new Date(),
        msg: "Please come to our table",
        from: Tools.getCookie("STATION"),
      });
    } else if (cmd === "B") {
      sendJsonMessage({
        table_id: order.table.uuid,
        table: order.table.name,
        server: order.server.name,
        time: new Date(),
        msg: "Please bring the bill",
        from: Tools.getCookie("STATION"),
      });
    } else {
    }
  };

  const updateOption = (opt) => {
    processFn(POSAdminPrc.UPDATE_ORDER_ITEM_OPTIONS, opt);
    if (typeof opt.delete === "boolean") {
      opDetailRef.current.hide();
    } else {
      setOptDtl(opt);
    }
  };

  useEffect(() => {
    AdminPrc.getAllEntities(
      "CRUD$Partner",
      token,
      (message) => setError(message),
      (data) => {
        setPartners([{ id: 0, name: "No Partner", amount: 0 }, ...data]);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    processFn(POSAdminPrc.SET_GUEST_CMD, guest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guest]);

  useEffect(() => {
    //    setHRatio(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    let dx = document.getElementById("command_div");
    if (dx != null) {
      if (window.innerHeight < dx.getBoundingClientRect().bottom) {
        setHRatio(hRatio - 0.005);
      } else if (window.innerHeight > dx.getBoundingClientRect().bottom + 10) {
        setHRatio(hRatio + 0.005);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hRatio, order]);

  useEffect(() => {
    if (configs.length === 0) return;
    let sw = configs.find((c) => c.name === "ws_server");
    if (typeof sw === "undefined") return;
    if (socketUrl !== sw.value && sw.value !== null && sw.value.length !== 0)
      setSocketUrl(sw.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const billedTemplate = (node) => {
    if (node["amount"] < 0) return "";
    return (
      <span className={node.changed ? "text-pink-600" : ""}>
        {node["amount"].toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </span>
    );
  };

  const buildGuests = () => {
    let ops = [];
    for (let i = 0; i !== order.guests; i++) {
      ops.push(
        <Fragment key={i}>
          <RadioButton
            inputId={`guest${i + 1}`}
            name="OIBelongs"
            value={i + 1}
            onChange={(e) => {
              setGuest(i + 1);
            }}
            checked={
              //oiIndex !== -1 && order.orderItems[oiIndex].guest === i + 1
              guest === i + 1
            }
          />
          <label className="font-medium ml-2 mr-5">{`Guest ${i + 1}`}</label>
        </Fragment>
      );
    }
    return ops;
  };

  const compareFin = (f1, f2) => {
    // if (f1.amount < 0) return -1;
    // if (f2.type < f1.type) return 1;
    // if (f2.type === f1.type) return 0;
    // if (f2.type > f1.type) return -1;
    return f1.row - f2.row;
  };

  const getOptionLabel = (opt, readOnly) => {
    let res = opt.modifier.name;
    let ops = [];
    if (opt.free) ops.push("Free");
    if (opt.easy) ops.push("Easy");
    if (opt.hlf) ops.push("Half");
    if (opt.dbl) ops.push("Double");
    if (opt.trp) ops.push("Triple");
    if (opt.left) ops.push("On Left ");
    if (opt.right) ops.push("On Right ");
    if (opt.note.length !== 0) ops.push("NOTE :" + opt.note);
    if (ops.length !== 0) res = res + " [" + ops.toString() + "]";
    if (readOnly) return res;
    return (
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-sm"
        label={res}
        onClick={(e) => {
          setOptDtl(opt);
          opDetailRef.current.toggle(e);
        }}
      />
    );
  };
  const paidTemplate = (node) => {
    if (node["amount"] > 0) return "";
    return (
      <span className={node.changed ? "text-pink-600" : ""}>
        {(-1 * node["amount"]).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </span>
    );
  };

  const setupAndShowOIMenu = (index) => {
    //setOiIndex(index);
    let oi = order.orderItems[index];
    let menu = [];
    if (!(oi.master && oi.seq === -1) && !(oi.served === oi.count)) {
      menu.push({
        name: "Options",
        icon: "pi pi-sliders-h text-5xl",
        command: () => processFn(POSAdminPrc.EDIT_ORDER_ITEM_CMD, index),
      });
    }
    if (oi.served !== oi.count)
      menu.push({
        name: "Note",
        icon: "pi pi-pencil text-5xl",
        command: () => processFn(POSAdminPrc.MEMO_ORDER_ITEM_CMD, index),
      });
    if (oi.master && oi.served !== oi.count) {
      menu.push({
        name: "More",
        icon: "pi pi-plus-circle text-5xl",
        command: () => processFn(POSAdminPrc.INC_ORDER_ITEM_CMD, index),
      });
    }

    if (!(oi.master && oi.seq === -1) && !(oi.parent !== 0)) {
      menu.push({
        name: "One More",
        icon: "pi pi-clone text-5xl",
        command: () => processFn(POSAdminPrc.CLONE_ORDER_ITEM_CMD, index),
      });
    }
    if (oi.master && !(oi.served === oi.count)) {
      menu.push({
        name: "Less",
        icon: "pi pi-minus-circle text-5xl",
        command: () => processFn(POSAdminPrc.DEC_ORDER_ITEM_CMD, index),
      });
    }
    if (order.type === POSAdminPrc.DINE_IN && order.guests !== 1) {
      menu.push({
        name: "Change Guest",
        icon: "pi pi-users text-5xl",
        command: () => processFn(POSAdminPrc.CHANGE_GUEST_CMD, index),
      });
    }
    if (oi.master && oi.served !== oi.count) {
      menu.push({
        name: "Delete",
        icon: "pi pi-trash text-5xl",
        command: () => processFn(POSAdminPrc.DEL_ORDER_ITEM_CMD, index),
      });
    }
    setOIMenu(menu);
    setShowOIAction(true);
  };

  return (
    <div
      id="OSBOX"
      className="field col-12 pt-2"
      style={{
        height: viewHeight + "px",
      }}
    >
      <ConfirmDialog />
      <ManagedDialog
        closeFn={setShowOIAction}
        visible={showOIAction}
        maximized={false}
        header="Select an action"
      >
        <SelectEntity objectSelection={oIMenu} processFn={processOIAction} />
      </ManagedDialog>
      <ManagedDialog
        closeFn={setShowHelpAction}
        visible={showHelpAction}
        maximized={false}
        header="How can we help?"
      >
        <SelectEntity
          objectSelection={helpMenu}
          processFn={processHelpAction}
        />
      </ManagedDialog>
      <OverlayPanel
        ref={opDetailRef}
        showCloseIcon
        style={{
          width: "500px",
          backgroundColor: "rgba(209,110,44,1)",
          borderRadius: "25px",
        }}
        dismissable
        //appendTo="self"
      >
        <OrderItemMenuOptionAttr
          optDtl={optDtl}
          updateOption={updateOption}
          user={user}
        />
      </OverlayPanel>
      <div className="grid">
        <div className="col-2">
          {!customerUI &&
            order.state !== POSAdminPrc.START &&
            (order.type === POSAdminPrc.PICK_UP ||
              order.type === POSAdminPrc.DELIVER) && (
              <Dropdown
                className="w-12"
                value={order.partner}
                options={partners}
                onChange={(e) => {
                  processFn(POSAdminPrc.SELECT_PARTNER_CMD, e.value);
                }}
                optionLabel="name"
                placeholder="Select a Partner"
              />
            )}
        </div>
        <div
          className="flex col-10 align-content-center justify-content-center"
          style={{
            height: viewHeight * 0.05 + "px",
          }}
        >
          <div className="p-2 text-2xl">
            <span className="font-semibold">{POSAdminPrc.getType(order)}</span>
            <span className="font-semibold text-orange-600 p-2 border-round">
              {order.type === POSAdminPrc.PICK_UP ||
              order.type === POSAdminPrc.DELIVER
                ? order.customer === null
                  ? ""
                  : " | " + order.customer.name
                : order.type === POSAdminPrc.DINE_IN
                ? order.table == null
                  ? ""
                  : " | Table: " + order.table.name
                : ""}
            </span>
            <span className="font-semibold text-blue-600 p-2 border-round">
              {order.type === POSAdminPrc.PICK_UP ||
              order.type === POSAdminPrc.DELIVER
                ? order.customer === null
                  ? ""
                  : " | " + order.customer.address
                : ""}
            </span>
          </div>
        </div>
        {order.guests >= 2 && (
          <div
            className="flex p-2 col-12 align-content-center justify-content-start"
            style={{
              height: viewHeight * 0.05 + "px",
            }}
          >
            <RadioButton
              inputId="table"
              name="OIBelongs"
              value="0"
              onChange={(e) => {
                setGuest(0);
              }}
              checked={
                //oiIndex === -1 || order.orderItems[oiIndex].guest === 0
                guest === 0
              }
            />
            <label className="font-medium ml-2 mr-5">Table</label>
            {buildGuests()}
          </div>
        )}
        <div className={customerUI ? "col-12 mb-1" : "col-9 mb-1"}>
          <ScrollPanel
            style={{
              height: viewHeight * hRatio + "px",
            }}
          >
            <ul className="list-none p-0 m-0">
              {order.orderItems
                .filter((oi) => guest === 0 || oi.guest === guest)
                .map((oi, index) => {
                  let ext = POSAdminPrc.getExtraCost(index, order.orderItems);
                  let name = oi.menu == null ? oi.deal.name : oi.menu.name;
                  let price =
                    oi.menu == null
                      ? oi.deal.price
                      : POSAdminPrc.getMenuPrice(oi);
                  if (
                    !oi.master &&
                    oi.deal == null &&
                    order.orderItems[oi.parent - 1].menu.count > 1
                  ) {
                    name = POSAdminPrc.seqNames[oi.seq];
                  }
                  return (
                    <li
                      key={index}
                      className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                    >
                      {oi.master && (
                        <div
                          className="text-700 w-6 md:w-2 font-medium"
                          onClick={(e) => {
                            setupAndShowOIMenu(index);
                          }}
                        >
                          {name}
                        </div>
                      )}
                      {!oi.master && (
                        <div className="text-500 w-6 md:w-2 font-medium pl-5">
                          {name}
                        </div>
                      )}
                      <div className="text-900 w-full md:w-9 md:flex-order-0 flex-order-1">
                        {oi.guest !== 0 && guest === 0 && (
                          <Chip
                            key="GST"
                            label={"Guest: " + oi.guest}
                            className="mr-2 mb-1 text-800 bg-yellow-100"
                          />
                        )}
                        {oi.master && (
                          <Chip
                            key="CNT"
                            label={oi.count}
                            className="mr-2 mb-1 text-800 bg-blue-100"
                          />
                        )}
                        {price !== 0 && oi.master && (
                          <Chip
                            key="PRICE"
                            label={price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "CAD",
                            })}
                            className="mr-2 mb-1 text-pink-500 bg-green-100"
                          />
                        )}
                        {oi.extra_cost !== 0 && (
                          <Chip
                            key="OI_EXTRA"
                            icon="pi pi-plus-circle"
                            label={oi.extra_cost.toLocaleString("en-US", {
                              style: "currency",
                              currency: "CAD",
                            })}
                            className="mr-2 mb-1 text-pink-800 bg-green-400"
                          />
                        )}
                        {ext !== 0 && ( //oi.master &&
                          <Chip
                            key="EXTRA"
                            icon="pi pi-plus-circle"
                            label={ext.toLocaleString("en-US", {
                              style: "currency",
                              currency: "CAD",
                            })}
                            className="mr-2 mb-1 text-pink-500 bg-green-100"
                          />
                        )}
                        {oi.options.map((op, index2) => (
                          <Chip
                            key={index2}
                            label={getOptionLabel(op, oi.served === oi.count)}
                            className="mr-2 mb-1"
                          />
                        ))}
                        {oi.note.length !== 0 && (
                          <Chip
                            key="NOTE"
                            label={"Item Note: " + oi.note}
                            className="mr-2 mb-1 text-800 bg-blue-200"
                          />
                        )}
                      </div>
                      {!POSAdminPrc.isPaid(order, guest) && (
                        <div className="w-1 flex justify-content-end">
                          <Button
                            icon="pi pi-ellipsis-h text-orange-500"
                            className="p-button-text"
                            onClick={(e) => {
                              setupAndShowOIMenu(index);
                            }}
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
          </ScrollPanel>
        </div>
        {!customerUI && (
          <div
            className="col-3 mb-1 relative bg-indigo-100"
            style={{
              height: viewHeight * hRatio + "px",
            }}
          >
            <DataTable
              className="bg-indigo-100"
              value={order.finances
                .filter((f) => f.show && (guest === 0 || f.guest === guest))
                .sort(compareFin)}
              scrollHeight={viewHeight * hRatio + "px"}
              sortField="type"
              scrollable
              // style={{
              //   heightd: viewHeight * 0.3 + "px",
              // }}
            >
              <Column
                className="bg-indigo-100"
                field="key"
                header="Item"
                style={{ width: "60%" }}
              ></Column>
              <Column
                className="bg-indigo-100"
                field="amount"
                header="Billed"
                body={billedTemplate}
                style={{ width: "20%" }}
              ></Column>
              <Column
                className="bg-indigo-100"
                field="paid"
                header="Paid"
                body={paidTemplate}
                style={{ width: "20%" }}
              ></Column>
            </DataTable>
            {POSAdminPrc.isPaid(order, guest) && (
              <img
                src="images/paid.png"
                alt=""
                height="200px"
                className="absolute opacity-50"
                style={{ top: "50px", left: "70px" }}
              />
            )}
          </div>
        )}
        <div className="col-12 mb-1">
          <div className="grid" id="command_div">
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.table !== null && (
                <div className="col-3 p-2">
                  <Button
                    label="Table"
                    iconPos="top"
                    icon="text-4xl pi pi-arrows-h"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.CHANGE_TABLE_CMD);
                    }}
                  />
                </div>
              )}
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.table !== null && (
                <div className="col-3 p-2">
                  <Button
                    label="Guest"
                    iconPos="top"
                    icon={
                      "text-4xl pi pi-user-" + (guest === 0 ? "plus" : "minus")
                    }
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.ADD_REMOVE_GUEST_CMD, guest);
                    }}
                  />
                </div>
              )}
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.customer !== null && (
                <div className="col-3 p-2">
                  <Button
                    label="Customer"
                    iconPos="top"
                    icon="text-4xl pi pi-user"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.SHOW_CUSTOMER_CMD);
                    }}
                  />
                </div>
              )}
            {order.state === POSAdminPrc.ADD_ORDER_ITEM_CMD &&
              order.orderItems.length === 0 &&
              order.customer !== null &&
              order.customer.last_order_id != null && (
                <div className="col-3 p-2">
                  <Button
                    label="Last"
                    iconPos="top"
                    icon="text-4xl pi pi-clock"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      setGuest(0);
                      processFn(POSAdminPrc.LOAD_LAST_ORDER_CMD);
                    }}
                  />
                </div>
              )}
            {order.state === POSAdminPrc.ADD_ORDER_ITEM_CMD &&
              order.orderItems.length === 0 &&
              order.customer !== null &&
              order.customer.last_order_id != null && (
                <div className="col-3 p-2">
                  <Button
                    label="History"
                    icon="text-4xl pi pi-history"
                    iconPos="top"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      setGuest(0);
                      processFn(POSAdminPrc.LOAD_ORDER_HIS_CMD);
                    }}
                  />
                </div>
              )}
            {order.state === POSAdminPrc.START && (
              <div className="col-3 p-2">
                <Button
                  label="New"
                  icon="text-4xl pi pi-shopping-cart"
                  iconPos="top"
                  className="p-button-outlined p-button-lg"
                  onClick={(e) => {
                    // confirmDialog({
                    //   message:
                    //     "An order is already in progress, are you sure you want to proceed?",
                    //   className: "text-3xl",
                    //   icon: "pi pi-info-circle text-3xl",
                    //   acceptClassName: "text-2xl p-button-danger",
                    //   rejectClassName: "text-2xl p-button-info",
                    //   accept: () => {
                    //     processFn(POSAdminPrc.GET_ORDER_TYPE);
                    //     setGuest(0);
                    //   },
                    //   reject: () => {},
                    // });
                    processFn(POSAdminPrc.GET_ORDER_TYPE);
                  }}
                />
              </div>
            )}
            {order.state === POSAdminPrc.START &&
              configs.find((c) => c.name === "has_dinein").value === "yes" && (
                <div className="col-3 p-2">
                  <Button
                    label="Dine In"
                    icon="text-4xl pi pi-tablet"
                    iconPos="top"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.CHANGE_TABLE_CMD);
                    }}
                  />
                </div>
              )}
            {order.state === POSAdminPrc.START && (
              <div className="col-3 p-2">
                <Button
                  label="Load"
                  icon="text-4xl pi pi-cloud-download"
                  iconPos="top"
                  className="p-button-outlined p-button-lg"
                  onClick={(e) => {
                    setGuest(0);
                    processFn(POSAdminPrc.LOAD_ORDER_CMD);
                  }}
                />
              </div>
            )}
            {order.state !== POSAdminPrc.START &&
              !POSAdminPrc.isPaid(order, guest) && (
                <div className="col-3 p-2">
                  <Button
                    label="Add"
                    icon="text-4xl pi pi-plus-circle"
                    iconPos="top"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.ADD_ORDER_ITEM_CMD);
                    }}
                  />
                </div>
              )}
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.orderItems.length !== 0 &&
              order.guests > 1 && (
                <div className="col-3 p-2">
                  <Button
                    label={order.billOption}
                    className="p-button-outlined p-button-lg"
                    iconPos="top"
                    icon="text-4xl pi pi-dollar"
                    onClick={(e) => {
                      processFn(POSAdminPrc.BILL_OPTIONS_CMD, order);
                    }}
                  />
                </div>
              )}
            {order.state !== POSAdminPrc.START &&
              order.orderItems.length !== 0 &&
              !POSAdminPrc.isPaid(order, guest) && (
                <div className="col-3 p-2">
                  <Button
                    label={customerUI ? "Send" : "Save"}
                    icon="text-4xl pi pi-cloud-upload"
                    iconPos="top"
                    disabled={!order.changed}
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.SAVE_ORDER_CMD);
                    }}
                  />
                </div>
              )}
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.orderItems.length !== 0 && (
                <div className="col-3 p-2">
                  <Button
                    label="Print"
                    icon="text-4xl pi pi-print"
                    iconPos="top"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.PRINT_CMD, order);
                    }}
                  />
                </div>
              )}
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.orderItems.length !== 0 &&
              !POSAdminPrc.isPaid(order, guest) && (
                <div className="col-3 p-2">
                  <Button
                    label="Pay"
                    icon="text-4xl pi pi-money-bill"
                    iconPos="top"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      processFn(POSAdminPrc.PAY_CMD, guest);
                    }}
                  />
                </div>
              )}
            {readyState === ReadyState.OPEN &&
              customerUI &&
              order.type === POSAdminPrc.DINE_IN && (
                <div className="col-3 p-2">
                  <Button
                    label="Get Help"
                    icon="text-4xl pi pi-bell"
                    iconPos="top"
                    className="p-button-outlined p-button-lg"
                    onClick={(e) => {
                      setShowHelpAction(true);
                    }}
                  />
                </div>
              )}
            {!customerUI && order.id !== 0 && (
              <div className="col-3 p-2">
                <Button
                  label="Cancel Order"
                  icon="text-4xl pi pi-times-circle"
                  iconPos="top"
                  className="p-button-outlined p-button-lg p-button-danger"
                  onClick={(e) => {
                    confirmDialog({
                      message: "Are you sure you want to cancel this order?",
                      className: "text-3xl",
                      icon: "pi pi-info-circle text-3xl",
                      acceptClassName: "text-2xl p-button-danger",
                      rejectClassName: "text-2xl p-button-info",
                      key: "d001",
                      accept: () => {
                        processFn(POSAdminPrc.CANCEL_ORDER_CMD);
                      },
                      reject: () => {},
                    });
                  }}
                />
              </div>
            )}
            {!customerUI &&
              order.state !== POSAdminPrc.START &&
              order.orderItems.length !== 0 && (
                <div className="col-3 p-2">
                  <Button
                    label="Start Over"
                    icon="text-4xl pi pi-undo"
                    iconPos="top"
                    className="p-button-outlined p-button-lg p-button-warning"
                    onClick={(e) => {
                      confirmDialog({
                        header: "Confirm",
                        message:
                          "An order is already in progress, are you sure you want to start over?",
                        className: "text-3xl",
                        icon: "pi pi-info-circle text-3xl",
                        acceptClassName: "text-2xl p-button-danger",
                        rejectClassName: "text-2xl p-button-info",
                        key: "d002",
                        accept: () => {
                          setGuest(0);
                          processFn(POSAdminPrc.DISCAD_ORDER_CHANGES_CMD);
                        },
                        reject: () => {
                          console.log("Rejected");
                        },
                        onHide: (result) => {
                          console.log(result);
                        },
                      });
                    }}
                  />
                </div>
              )}
            {closeFn != null && order.state !== POSAdminPrc.START && (
              <div className="col-3 p-2">
                <Button
                  key="discardBut"
                  label="Discard"
                  icon="text-4xl pi pi-times"
                  iconPos="top"
                  className="p-button-outlined p-button-lg p-button-danger"
                  onClick={(e) => {
                    if (order.orderItems.length !== 0) {
                      confirmDialog({
                        message:
                          "An order is already in progress, are you sure you want to leave the order page?",
                        className: "text-3xl",
                        icon: "pi pi-info-circle text-3xl",
                        acceptClassName: "text-2xl p-button-danger",
                        rejectClassName: "text-2xl p-button-info",
                        key: "d003",
                        accept: () => {
                          setGuest(0);
                          processFn(POSAdminPrc.DISCAD_ORDER_CMD);
                        },
                        reject: () => {},
                      });
                    } else {
                      setGuest(0);
                      processFn(POSAdminPrc.DISCAD_ORDER_CMD);
                    }
                  }}
                />
              </div>
            )}{" "}
            {closeFn != null && order.state === POSAdminPrc.START && (
              <div className="col-3 p-2">
                <Button
                  label="Close"
                  iconPos="top"
                  icon="text-4xl pi pi-sign-out"
                  className="p-button-outlined p-button-lg"
                  onClick={(e) => {
                    if (order.orderItems.length !== 0)
                      confirmDialog({
                        className: "text-3xl",
                        icon: "pi pi-info-circle text-3xl",
                        acceptClassName: "text-2xl p-button-danger",
                        rejectClassName: "text-2xl p-button-info",
                        key: "d004",
                        message:
                          "Are you sure you want to close the order screen?",
                        accept: () => {
                          closeFn(false);
                        },
                        reject: () => {},
                      });
                    else {
                      closeFn(false);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderScreen;
